import { FC, ReactNode, useEffect, useState } from "react";
import {
  useTcoAssumptions,
  useTcoConfig,
  useTcoReport,
  useTcoCompleteReport,
  useTcoCompleteReportForTooltip,
} from "v2/services/tcoServices";
import {
  TcoCalculationContext,
  ITcoCalculationContext,
} from "v2/store/TcoCalculationContext";
import {
  Options,
  Sustainability,
  SustainabilityCategory,
  TcoAssumptionsPayload,
  TcoReportPayload,
} from "v2/types/apiPayloadTypes";
import {
  TcoConfig,
  TcoReport,
  TcoFinalReport,
} from "v2/types/apiResponseTypes";
import { CodeNamePair, discountRate } from "v2/types/commonTypes";
import { Currency } from "v2/types/currencyType";
import { CustomerDetail } from "v2/types/customerDetailType";
import { ProductDetails } from "v2/types/productTypes";
import { deviceMap, tcoAssumptionMap } from "v2/utilities/mapping";
import defaultDevices from "v2/store/DefaultDevices.json";
import CurrencySymbols from "../store/CurrencySymbols.json";
import { isDeviceValid, validateTcoReportPayload } from "../utilities/helper";

const defaultCountry: CodeNamePair = { code: "US", name: "United States" };
const initialCustomerInfor: CustomerDetail = {
  companyName: "",
  industry: "",
  role: "",
  companySize: "",
};

const defaultOptions: Options = {
  includeCameraPeripheralSavings: true,
  includeLightsPeripheralSavings: true,
  includeHeadsetPeripheralSavings: true,
  includeDockCost: true,
  includeTypeCoverCost: false,
  includePenCost: false,
  includeExtraPowerSupplyCost: false,
  includeDeviceConsolidationSavings: true,
  includeResidualValueSavings: true,
  include3rdPartyServicesSavings: true,
  wantAccessories: true,
  default: true,
  custom: false,
};

const defaultSustainabilityCategory: SustainabilityCategory = {
  checked: false,
  unitPrice: 0,
  surfaceValue: 0,
  otherPcValue: 0,
};
const defaultSustainability: Sustainability = {
  CarbonEmissions: defaultSustainabilityCategory,
  Energy: defaultSustainabilityCategory,
};

const initialDisCountRate: discountRate = {
  pbp: 6.2,
  npv: 6.2,
};

const initialPayload: TcoReportPayload = {
  assumptions: {} as TcoAssumptionsPayload,
  country: "",
  currency: "",
  years: 3,
  currencyConversionMultipleFromUSD: 0,
  currencyConversionMultipleToUSD: 0,
  deviceMixPortfolio: [],
  options: defaultOptions,
  sustainability: defaultSustainability,
  industry: "default",
};

const updatedDefaultProducts = defaultDevices.defaultProducts.map(
  (product) => ({
    ...product,
    quantity: undefined,
  })
);
const TcoCalculationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { data } = useTcoConfig();
  const { data: assumptions } = useTcoAssumptions();
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  const [customerInfo, setCustomerInfo] =
    useState<CustomerDetail>(initialCustomerInfor);
  const [selectedDevices, setSelectedDevices] = useState<ProductDetails[]>(
    updatedDefaultProducts
  );
  const [selectedYears, setSelectedYears] = useState<number>(3);
  const [tcoReport, setTcoReport] = useState<TcoReport>({} as TcoReport);
  const [tcoReportPayload, setTcoReportPayload] =
    useState<TcoReportPayload>(initialPayload);
  const [disCountRate, setDisCountRate] =
    useState<discountRate>(initialDisCountRate);
  const [currency, setCurrency] = useState<Currency>();
  const [tcoCompleteReport, setTcoCompleteReport] = useState<TcoFinalReport>(
    {} as TcoFinalReport
  );
  const [tcoReportPayloadPerDevice, setTcoReportPayloadPerDevice] =
    useState<TcoReportPayload>({ ...initialPayload });
  const [tcoCompleteReportPerDevice, setTcoCompleteReportPerDevice] =
    useState<TcoFinalReport>({} as TcoFinalReport);
  useEffect(() => {
    if (selectedCountry) {
      let currentCurrency = data?.currencies.find(
        (currency) => currency.countryCode === selectedCountry.code
      );
      const symbol = CurrencySymbols.currencySymbols.find(
        (symbol) => symbol.code === currentCurrency?.currencyCode
      );
      if (currentCurrency && symbol) {
        currentCurrency = { ...currentCurrency, symbol: symbol?.symbol };
        currentCurrency && setCurrency({ ...currentCurrency });
        setTcoReportPayload((prev: TcoReportPayload) => ({
          ...prev,
          country: selectedCountry.code,
          currency: currentCurrency?.currencyCode as string,
          currencyConversionMultipleFromUSD:
            currentCurrency?.currencyPerUSD as number,
          currencyConversionMultipleToUSD:
            currentCurrency?.usdPerCurrency as number,
        }));
      }
    }
  }, [data?.currencies, selectedCountry]);

  useEffect(() => {
    if (assumptions) {
      setTcoReportPayload((prev) => ({
        ...prev,
        assumptions: tcoAssumptionMap(assumptions),
      }));
    }
  }, [assumptions]);

  useEffect(() => {
    if (selectedDevices) {
      const isValid = selectedDevices.every(isDeviceValid);
      if (!isValid) return;
      setTcoReportPayload((prev) => ({
        ...prev,
        deviceMixPortfolio: deviceMap(selectedDevices),
      }));
    }
  }, [selectedDevices]);

  const { data: report, refetch: refetchTcoReport } =
    useTcoReport(tcoReportPayload);
  useEffect(() => {
    if (report) {
      setTcoReport(report);
    }
  }, [report]);

  const { data: completereport, refetch: refetchTcoCompleteReport } =
    useTcoCompleteReport(tcoReportPayload);
  useEffect(() => {
    if (completereport) {
      setTcoCompleteReport(completereport);
    }
  }, [completereport]);

  const { data: reportPerDevice, refetch: refetchTcoReportPerDevice } =
    useTcoCompleteReportForTooltip(tcoReportPayloadPerDevice);
  useEffect(() => {
    if (reportPerDevice) {
      setTcoCompleteReportPerDevice(reportPerDevice);
    }
  }, [reportPerDevice]);
  useEffect(() => {
    if (validateTcoReportPayload(tcoReportPayload)) {
      refetchTcoReport();
      refetchTcoCompleteReport();
    }
  }, [tcoReportPayload]);

  useEffect(() => {
    if (validateTcoReportPayload(tcoReportPayloadPerDevice)) {
      refetchTcoReportPerDevice();
    }
  }, [tcoReportPayloadPerDevice]);

  const value: ITcoCalculationContext = {
    tcoConfig: data as TcoConfig,
    selectedCountry,
    currency: currency as Currency,
    setSelectedCountry,
    customerInfo,
    setCustomerInfo,
    selectedDevices: selectedDevices,
    setSelectedDevices: setSelectedDevices,
    tcoReport,
    tcoCompleteReport,
    setTcoReport,
    selectedYears,
    setSelectedYears,
    tcoReportPayload,
    setTcoReportPayload,
    tcoAssumptions: assumptions ?? [],
    discountRate: disCountRate,
    setDisCountRate,
    setTcoCompleteReport,
    tcoReportPayloadPerDevice,
    setTcoReportPayloadPerDevice,
    tcoCompleteReportPerDevice,
    setTcoCompleteReportPerDevice,
  };

  return (
    <TcoCalculationContext.Provider value={value}>
      {children}
    </TcoCalculationContext.Provider>
  );
};

export default TcoCalculationProvider;
