import { mergeStyleSets, Stack } from "@fluentui/react";
import { InfoLabel, Switch, useId } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import LabelledInput from "../common/LabelledInput";
import {
    convertFromOtherToUSD,
    convertFromUSDToOther,
    validateDecimal,
} from "v2/utilities/currencyConverter";
import { AdvancedInput, AdvancedInputItem } from "v2/types/advancedInput";
import { convertNumberToString, formatNumber } from "v2/utilities/helper";
import { Currency } from "v2/types/currencyType";

type DeviceCostType = {
    [key: string]: DeviceCostFields;
};
type DeviceCostFields = {
    [key: string]: string | boolean;
};
const styles = mergeStyleSets({
    title: {
        fontWeight: "600",
        color: "#1B1B1B",
        "&& .fui-PopoverSurface": {
            padding: "0.7rem 1.5rem",
            maxWidth: "360px",
            span: {
                display: "block",
                paddingBottom: "0.7rem",
            },
        },
    },
    inputWidth: {
        "&& .ms-Stack": {
            width: "100%",
        },
        "&& .fui-Switch": {
            width: "80%",
        },
    },
});

const deviceCostFields = [
    {
        title: "Device Costs",
        apiLabel: "Total Device Costs",
        titleTooltip: "This is the total device costs",
        key: "deviceCosts",
        data: [
            {
                key: "surfaceValue",
                label: "Surface Value",
                isAmount: true,
            },
            {
                key: "pcValue",
                label: "Other PC Value",
                isAmount: true,
            },
        ],
    },
    {
        title: "Accessories Costs",
        apiLabel: "Total Accessories Cost",
        titleTooltip:
            "This is the total cost for all accessories entered into the Assumptions Editor. (Accessories such as docking stations, keyboards, Surface Pens, and Power Supply)",
        key: "accessoriesCosts",
        data: [
            {
                key: "surfaceValue",
                label: "Surface Value",
                isAmount: true,
            },
            {
                key: "pcValue",
                label: "Other PC Value",
                isAmount: true,
            },
        ],
    },
    {
        title: "M365 Licensing Costs",
        apiLabel: "Total M365 Licensing Costs",
        titleTooltip: "This is the total cost for your Microsoft 365 license",
        key: "m365Costs",
        data: [
            {
                key: "surfaceValue",
                label: "Surface Value",
                isAmount: true,
            },
            {
                key: "pcValue",
                label: "Other PC Value",
                isAmount: true,
            },
        ],
    },
    {
        title: "Extended Warranty & Maintenance Costs",
        apiLabel: "Total Extended Warranty & Maintenance Costs",
        titleTooltip:
            "This is the total extended warranty and maintenance cost for your Surface device and Other PC (from the assumptions editor)",
        key: "maintenanceCosts",
        data: [
            {
                key: "surfaceValue",
                label: "Surface Value",
                isAmount: true,
            },
            {
                key: "pcValue",
                label: "Other PC Value",
                isAmount: true,
            },
        ],
    },
];
const DeviceCosts: React.FC = () => {
    const { tcoCompleteReport, currency, tcoReportPayload, setTcoReportPayload } =
        useTcoCalculationContext();
    const [deviceCostState, setDeviceCostState] = useState<DeviceCostType>(
        {} as DeviceCostType
    );
    const [initialDeviceCostState, setInitialDeviceCostState] =
        useState<DeviceCostType>({} as DeviceCostType);

    useEffect(() => {
        if (tcoCompleteReport?.totalCosts?.rows) {
            const hasTotalDeviceCosts = tcoCompleteReport.totalCosts.rows.find(
                (row) => row.label === "Total Device Costs"
            );

            if (!hasTotalDeviceCosts) {
                // Update tcoReportPayload to trigger a refetch
                setTcoReportPayload((prev) => ({
                    ...prev,
                    refetchTrigger: Date.now(),
                }));
            }
        }
    }, [tcoCompleteReport]);

    useEffect(() => {
        const output = deviceCostFields.reduce((acc, field) => {
            const matchingRow = tcoCompleteReport?.totalCosts?.rows?.find((row) =>
                row.label.includes(field.apiLabel)
            );
            if (matchingRow) {
                const pcValue =
                    currency?.currencyCode !== "USD"
                        ? convertFromUSDToOther(matchingRow.pcValue, currency)
                        : matchingRow.pcValue;
                const surfaceValue =
                    currency?.currencyCode !== "USD"
                        ? convertFromUSDToOther(matchingRow.surfaceValue, currency)
                        : matchingRow.surfaceValue;

                acc[field.key] = {
                    ...deviceCostState[field.key],
                    pcValue: convertNumberToString(Math.round(pcValue)),
                    surfaceValue: convertNumberToString(Math.round(surfaceValue)),
                    checked: true,
                    value: Math.round(surfaceValue).toString(),
                };
                if (deviceCostState["accessoriesSavings"])
                    acc["accessoriesSavings"] = deviceCostState["accessoriesSavings"];
            }
            else {
                acc[field.key] = {
                    ...deviceCostState[field.key],
                    pcValue: deviceCostState[field.key]?.pcValue ?? "0",
                    surfaceValue: deviceCostState[field.key]?.surfaceValue ?? "0",
                    checked: deviceCostState[field.key]?.checked ?? false,
                    value: deviceCostState[field.key]?.value ?? "0",
                };
            }
            return acc;
        }, {} as DeviceCostType);
        setDeviceCostState(output);
        if (Object.keys(initialDeviceCostState).length === 0)
            setInitialDeviceCostState(output);
    }, [currency, tcoCompleteReport]);

    useEffect(() => {
        if (Object.keys(initialDeviceCostState).length === 0) {
            const output = deviceCostFields.reduce((acc, field) => {
                const matchingRow = tcoCompleteReport?.totalCosts?.rows?.find((row) =>
                    row.label.includes(field.apiLabel)
                );
                if (matchingRow) {
                    const pcValue = matchingRow.pcValue;
                    const surfaceValue = matchingRow.surfaceValue;

                    acc[field.key] = {
                        ...deviceCostState[field.key],
                        pcValue: convertNumberToString(pcValue),
                        surfaceValue: convertNumberToString(surfaceValue),
                        checked: true,
                        value: Math.round(surfaceValue).toString(),
                    };

                }
                return acc;
            }, {} as DeviceCostType);
            setInitialDeviceCostState(output);
        }
    }, [currency]);

    const removeCommasAndConvert = (
        value: string,
        currency: Currency | undefined
    ): string => {
        const cleanedValue = value?.toString()?.replace(/,/g, "");
        return currency?.countryCode !== "US"
            ? convertFromOtherToUSD(Number(cleanedValue), currency).toString()
            : cleanedValue;
    };
    const removeCommas = (value: any) => {
        return value?.toString()?.replace(/,/g, "");
    };
    const onBlur = (
        fieldKey: keyof AdvancedInput,
        key: string,
        e: React.FocusEvent<HTMLInputElement>
    ) => {
        setTcoReportPayload((prev) => {
            const updatedAdvancedInput = {
                ...(prev.advancedInput ?? ({} as AdvancedInput)),
                [fieldKey]: {
                    ...deviceCostState[fieldKey],
                    pcValue: removeCommasAndConvert(
                        deviceCostState[fieldKey].pcValue.toString(),
                        currency
                    ),
                    surfaceValue: removeCommasAndConvert(
                        deviceCostState[fieldKey].surfaceValue.toString(),
                        currency
                    ),
                    value: removeCommasAndConvert(
                        deviceCostState[fieldKey].value.toString(),
                        currency
                    ),
                },
            };
            if (
                deviceCostState[fieldKey] &&
                !deviceCostState[fieldKey].isSurfaceValueChanged &&
                !deviceCostState[fieldKey].isOtherPCValueChanged
            ) {
                delete updatedAdvancedInput[fieldKey];
            }
            if (prev?.advancedInput?.accessoriesCosts) {
                const accessoriesSavings = prev.advancedInput.accessoriesCosts;
                accessoriesSavings.value = `${Math.round(Number(removeCommas(accessoriesSavings?.pcValue) ?? 0) - Number(removeCommas(accessoriesSavings.surfaceValue) ?? 0))}`;
                updatedAdvancedInput.accessoriesSavings = accessoriesSavings;
            } else {
                delete updatedAdvancedInput.accessoriesSavings;
            }

            return {
                ...prev,
                advancedInput: updatedAdvancedInput,
            };
        });
    };
    const onInputChange = (fieldKey: string, key: string, value: string) => {
        value = value.replaceAll(",", "");
        if (!validateDecimal(value)) return;
        value = value || "0";
        setDeviceCostState((prevState) => {
            let newData = {
                ...prevState[fieldKey],
                [key]: value,
            };
            const initialValueInUSD = initialDeviceCostState[fieldKey]?.[key].toString().replaceAll(",", "");
            const newValue = currency?.countryCode !== "US"
                ? convertFromOtherToUSD(Number(value), currency).toString()
                : value;
            const isValueChanged = Number(initialValueInUSD) !== Math.round(Number(newValue));

            if (key === "surfaceValue") {
                newData.value = value;
                isValueChanged
                    ? (newData.isSurfaceValueChanged = true)
                    : delete newData.isSurfaceValueChanged;
            } else if (key === "pcValue") {
                isValueChanged
                    ? (newData.isOtherPCValueChanged = true)
                    : delete newData.isOtherPCValueChanged;
            }

            if (
                prevState["accessoriesCosts"].isSurfaceValueChanged ||
                prevState["accessoriesCosts"].isOtherPCValueChanged
            ) {
                const newDataSavings = {
                    isSurfaceValueChanged:
                        prevState["accessoriesCosts"].isSurfaceValueChanged,
                    isOtherPCValueChanged:
                        prevState["accessoriesCosts"].isOtherPCValueChanged,
                    value: (
                        Math.round(
                            Number(removeCommas(prevState["accessoriesCosts"].surfaceValue))
                        ) -
                        Math.round(
                            Number(removeCommas(prevState["accessoriesCosts"].pcValue))
                        )
                    ).toString(),
                    pcValue: Math.round(
                        Number(removeCommas(prevState["accessoriesCosts"].pcValue))
                    ).toString(),
                    surfaceValue: Math.round(
                        Number(removeCommas(prevState["accessoriesCosts"].surfaceValue))
                    ).toString(),
                    checked: true,
                } as DeviceCostFields;
                return {
                    ...prevState,
                    [fieldKey]: newData,
                    accessoriesSavings: newDataSavings,
                };
            } else {
                delete prevState["accessoriesSavings"];
                return {
                    ...prevState,
                    [fieldKey]: newData,
                };
            }

        });
    };

    const onCheckboxChange = (fieldKey: keyof AdvancedInput, checked: boolean) => {
        setTcoReportPayload((prev) => {
            const updatedAdvancedInput = {
                ...(prev.advancedInput ?? ({} as AdvancedInput)),
                [fieldKey]: {
                    ...deviceCostState[fieldKey],
                    checked: checked,
                    // pcValue: checked ? deviceCostState[fieldKey]?.pcValue : 0,
                    // surfaceValue: checked ? deviceCostState[fieldKey]?.surfaceValue : 0,
                    // value: checked ? deviceCostState[fieldKey]?.value : 0,
                    // isSurfaceValueChanged: checked ? deviceCostState[fieldKey]?.isSurfaceValueChanged : true,
                    // isOtherPCValueChanged: checked ? deviceCostState[fieldKey]?.isOtherPCValueChanged : true,
                },
            };
            if (checked) {
                delete updatedAdvancedInput[fieldKey];
            }
            return {
                ...prev,
                advancedInput: updatedAdvancedInput,
            };
        });
    }

    return (
        <Stack
            styles={{ root: { padding: "0px 0px 0px 50px", marginTop: "16px" } }}
            tokens={{ childrenGap: 16 }}
        >
            {deviceCostFields.map((field) => (
                <Stack key={field.title} tokens={{ childrenGap: 8 }}>
                    <InfoLabel
                        size={"medium"}
                        info={field.titleTooltip}
                        className={styles.title}
                    >
                        {field.title}
                    </InfoLabel>
                    <Stack
                        horizontal
                        tokens={{ childrenGap: 20 }}
                        verticalAlign="end"
                        className={styles.inputWidth}
                    >
                        {field.data.map((data) => (
                            <LabelledInput
                                label={data.label}
                                isAmount={true}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    onInputChange(field.key, data.key, value);
                                }}
                                value={`${deviceCostState[field.key]?.[data?.key]}` ?? ""}
                                key={data?.key}
                                disabled={!deviceCostState[field.key]?.checked ?? false}
                                onBlur={(ev) => onBlur(field.key as keyof AdvancedInput, data.key, ev)}
                            />
                        ))}
                        <Switch
                            checked={
                                (deviceCostState[field.key]?.checked as boolean) ?? false
                            }
                            onChange={(e) => {
                                setDeviceCostState((prevState) => ({
                                    ...prevState,
                                    [field.key]: {
                                        ...prevState[field.key],
                                        checked: e.target.checked ?? false,
                                    },
                                }));
                                onCheckboxChange(field.key as keyof AdvancedInput, e.target.checked);
                            }}
                            label={"Include in TCO Report"}
                        />
                    </Stack>
                </Stack>
            ))}
        </Stack>
    );
};

export default DeviceCosts;
