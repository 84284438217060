import { mergeStyleSets, Stack } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import LabelledInput from "../common/LabelledInput";
import { Field, InfoLabel, LabelProps, Radio, RadioGroup } from "@fluentui/react-components";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import { convertFromOtherToUSD } from "v2/utilities/currencyConverter";
import { convertStringToNumber } from "utils/helpers";
import tooltipData from "v2/store/tooltipData.json";

type SurfaceAndOtherPc = {
  averagePCCost: string;
  numberOfDevicesConsolidated: string;
  include3rdPartyServicesSavings: boolean;
  includeResidualValueSavings: boolean;
  includeDeviceConsolidationSavings: boolean;
  [key: string]: string | boolean;
};

type FieldData = {
  label: string;
  id: string;
  type: "input" | "radio";
  isAmount?: boolean;
  tooltip?: string;
};
const styles = mergeStyleSets({
  tooltip: {
    "&& .fui-PopoverSurface": {
      width: "500px",
      maxWidth: "90%",
      padding: "0.7rem 1.5rem",
      // fontSize: "14px",
      // fontWeight: "400",
    }
  }
});
const SurfaceAndOtherPC: FC = () => {
  const { tcoReportPayload, setTcoReportPayload, currency } =
    useTcoCalculationContext();
  const [assumptions, setAssumptions] = useState<SurfaceAndOtherPc>(
    {} as SurfaceAndOtherPc
  );

  const onOptionsChange = (key: string, data: string) => {
    const value = data === "true";
    setAssumptions((prev) => ({
      ...prev,
      [key]: value,
    }));
    setTcoReportPayload((prev) => ({
      ...prev,
      options: { ...prev.options, [key]: value },
    }));
  };

  const onAssumptionChange = (key: string, data: string) => {
    setAssumptions((prev) => ({ ...prev, [key]: data }));
  };

  const onBlur = (key: string, data: string) => {
    const value = convertStringToNumber(data);
    setTcoReportPayload((prev) => ({
      ...prev,
      assumptions: {
        ...prev.assumptions,
        [key]: convertFromOtherToUSD(value, currency),
      },
    }));
  };

  const fieldData: FieldData[] = [
    {
      label:
        "What is the average cost of an alternative device you're considering?",
      id: "averagePCCost",
      type: "input",
      isAmount: true,
    },
    {
      label: "How many devices to expect to consolidate?",
      id: "numberOfDevicesConsolidated",
      type: "input",
      isAmount: false,
    },
    {
      label:
        "Do you expect to purchase fewer 3rd party support and maintenance services?",
      id: "include3rdPartyServicesSavings",
      type: "radio",
      tooltip: tooltipData.reducedSupport,
    },
    {
      label:
        "Do you expect to consolidate devices with your Surface device purchase?",
      id: "includeDeviceConsolidationSavings",
      type: "radio",
      tooltip: tooltipData.deviceConsolidation,
    },
    {
      label: "Do you sell your devices when you no longer need them?",
      id: "includeResidualValueSavings",
      type: "radio",
      tooltip: tooltipData.increasedResidualValue,
    },
  ];

  useEffect(() => {
    const assumptions = tcoReportPayload.assumptions;
    const options = tcoReportPayload.options;
    setAssumptions({
      averagePCCost: convertFromOtherToUSD(
        assumptions.averagePCCost,
        currency
      ).toString(),
      numberOfDevicesConsolidated: convertFromOtherToUSD(
        assumptions.numberOfDevicesConsolidated,
        currency
      ).toString(),
      include3rdPartyServicesSavings: options.include3rdPartyServicesSavings,
      includeResidualValueSavings: options.includeResidualValueSavings,
      includeDeviceConsolidationSavings:
        options.includeDeviceConsolidationSavings,
    });
  }, []);

  useEffect(() => {
    setAssumptions((preState) => ({
      ...preState,
      averagePCCost: convertFromOtherToUSD(
        convertStringToNumber(preState.averagePCCost),
        currency
      ).toString(),
    }));
  }, [currency]);

  return (
    <Stack
      tokens={{ childrenGap: 20 }}
      styles={{ root: { padding: "20px 0px 0px 50px", width: "fit-content" } }}
    >
      {fieldData.map((item) => (
        <Stack key={item.id}>
          {item.type === "input" ? (
            <LabelledInput
              key={item.id}
              label={item.label}
              infoLabel={item.tooltip ?? ""}
              maxWidth={378}
              isAmount={item.isAmount}
              value={`${assumptions?.[item.id]}`}
              onChange={(ev, data) => onAssumptionChange(item.id, data.value)}
              onBlur={(ev) => onBlur(item.id, ev.target.value)}
              type="number"
            />
          ) : (
            <Field
              label={
                <InfoLabel info={item.tooltip ?? ""} className={styles.tooltip}>
                  {item.label}
                </InfoLabel>
              }
            >
              <RadioGroup
                key={item.id}
                value={`${assumptions[item.id]}`}
                layout="horizontal"
                onChange={(ev, data) => onOptionsChange(item.id, data.value)}
              >
                <Radio value={"true"} label={"Yes"} />
                <Radio value={"false"} label={"No"} />
              </RadioGroup>
            </Field>
          )}
        </Stack>
      ))}
    </Stack>
  );
};

export default SurfaceAndOtherPC;
