import { useQuery } from "@tanstack/react-query";
import { validateTcoReportPayload } from "v2/utilities/helper";
import { TcoReportPayload } from "v2/types/apiPayloadTypes";
import {
  TcoAssumption,
  TcoFinalReport,
  TcoConfig,
  TcoReport,
} from "v2/types/apiResponseTypes";
import axiosClient from "v2/utilities/axiosClient";
import { roundValues } from "v2/utilities/helper";

const API_CODE = process.env.REACT_APP_API_CODE;

export const useTcoConfig = () => {
  return useQuery({
    queryKey: ["tcoConfig"],
    queryFn: getTcoConfig,
    refetchOnWindowFocus: false,
  });
};

const getTcoConfig = async (): Promise<TcoConfig> => {
  const response = await axiosClient.post<TcoConfig>(
    "api/tco-config?code=" + API_CODE
  );
  return response.data;
};

export const useTcoAssumptions = () => {
  return useQuery({
    queryKey: ["tcoAssumptions"],
    queryFn: getTcoAssumptions,
    refetchOnWindowFocus: false,
  });
};

const getTcoAssumptions = async (): Promise<TcoAssumption[]> => {
  const response = await axiosClient.post<{
    value: { assumptions: TcoAssumption[] };
  }>("api/tco-assumptions?code=" + API_CODE);
  return response.data.value.assumptions;
};

export const useTcoReport = (data: TcoReportPayload) => {
  return useQuery({
    queryKey: ["tcoReport"],
    queryFn: () => getTcoReport(data),
    refetchOnWindowFocus: false,
    enabled: validateTcoReportPayload(data),
  });
};

const getTcoReport = async (data: TcoReportPayload): Promise<TcoReport> => {
  const response = await axiosClient.post<{ value: TcoReport }>(
    "api/tco-report?code=" + API_CODE,
    data
  );
  return response.data.value;
};

const getTcoCompleteReport = async (
  data: TcoReportPayload
): Promise<TcoFinalReport> => {
  const response = await axiosClient.post<{ value: TcoFinalReport }>(
    "api/tco-report-complete?code=" + API_CODE,
    data
  );
  const updatedValue = response.data.value;
  return updatedValue;
};

export const useTcoCompleteReport = (data: TcoReportPayload) => {
  return useQuery({
    queryKey: ["tcoCompleteReport"],
    queryFn: () => getTcoCompleteReport(data),
    refetchOnWindowFocus: false,
    enabled: validateTcoReportPayload(data),
  });
};

export const useTcoCompleteReportForTooltip = (data: TcoReportPayload) => {
  return useQuery({
    queryKey: ["tcoCompleteReportTooltip"],
    queryFn: () => getTcoCompleteReport(data),
    refetchOnWindowFocus: false,
    enabled: validateTcoReportPayload(data),
  });
};
