import { Label, mergeStyleSets, Stack, StackItem } from "@fluentui/react";
import {
  Checkbox,
  CheckboxOnChangeData,
  Field,
  InfoLabel,
  Input,
  Radio,
  RadioGroup,
  RadioGroupOnChangeData,
  Text,
} from "@fluentui/react-components";
import { ChangeEvent, useEffect, useId, useMemo, useState } from "react";
import { Currency, CurrencySymbol } from "v2/types/currencyType";
import CustomAssumptionsItem from "./CustomAssumptionsItem";
import {
  convertFromOtherToUSD,
  convertFromUSDToOther,
  formatCurrency,
  validateDecimal,
} from "v2/utilities/currencyConverter";
import CurrencySymbols from "v2/store/CurrencySymbols.json";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import { formatNumber } from "v2/utilities/helper";
import { TcoAssumptionsPayload } from "v2/types/apiPayloadTypes";
import { BUSINESS_CASE_URL } from "v2/constants";

const style = mergeStyleSets({
  extenedWarrantyMainWrapper: {
    paddingLeft: "48px",
  },
  extendedWarrantyFooter: {
    maxWidth: "48%",
  },
  customAssumtionsRowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
  },
  customAssumtionsRowInput: {
    background: "#F0F0F0 !important",
  },
  customAssumtionsRowCheckbox: {
    alignSelf: "flex-end",
    minWidth: "127px",
  },
  tooltipStyle: {
    "&& .fui-PopoverSurface": {
      padding: "0.7rem 1.5rem",
      width: 380,
      maxWidth: "90%",
      span: {
        display: "block",
        paddingBottom: "0.7rem",
      },
    },
  },
});
export type CustomAssumptionsItems = {
  name: String;
  status: String;
  surfaceInput: String;
  otherPCInput: String;
};
const customAssumptionsItems: CustomAssumptionsItems[] = [
  {
    name: "Camera",
    status: "customCameraChecked",
    surfaceInput: "SurfaceCameraCost",
    otherPCInput: "PCCameraCost",
  },
  {
    name: "Light",
    status: "customLightChecked",
    surfaceInput: "SurfaceLightsCost",
    otherPCInput: "PCLightsCost",
  },
  {
    name: "Headset",
    status: "customHeadsetChecked",
    surfaceInput: "SurfaceHeadsetCost",
    otherPCInput: "PCHeadsetCost",
  },
  {
    name: "Dock",
    status: "customDockChecked",
    surfaceInput: "SurfaceDockCost",
    otherPCInput: "PCDockCost",
  },
  {
    name: "Pen",
    status: "customPenChecked",
    surfaceInput: "SurfacePenCost",
    otherPCInput: "PCPenCost",
  },
  {
    name: "Keyboard",
    status: "customKeyboardChecked",
    surfaceInput: "SurfaceTypeCoverCost",
    otherPCInput: "PCTypeCoverCost",
  },
  {
    name: "Power Supply",
    status: "customPowerSupplyChecked",
    surfaceInput: "SurfacePowerSupplyCost",
    otherPCInput: "PCPowerSupplyCost",
  },
];
const AccessoriesAndExtendedWarranty: React.FC = () => {
  const {
    tcoReportPayload,
    selectedYears,
    setTcoReportPayload,
    selectedCountry,
    tcoConfig,
    currency,
  } = useTcoCalculationContext();

  const [customAssumptionState, setCustomAssumptionState] = useState({
    sameAccessoryCostChecked: false,
    customCameraChecked: false,
    customLightChecked: false,
    customHeadsetChecked: false,
    customDockChecked: false,
    customPenChecked: false,
    customKeyboardChecked: false,
    customPowerSupplyChecked: false,
  });
  const [pcWarrantyAndMaintenanceCost, setPcWarrantyAndMaintenanceCost] =
    useState(
      (
        selectedYears *
        convertFromUSDToOther(
          tcoReportPayload.assumptions.pcWarrantyAndMaintenanceCost,
          currency
        )
      ).toFixed(2)
    );

  const [warrantyAndMaintenanceCost, setWarrantyAndMaintenanceCost] = useState(
    (
      selectedYears *
      convertFromUSDToOther(
        tcoReportPayload.assumptions.warrantyAndMaintenanceCost,
        currency
      )
    ).toFixed(2)
  );

  useEffect(() => {
    setPcWarrantyAndMaintenanceCost(
      (
        selectedYears *
        convertFromUSDToOther(
          tcoReportPayload.assumptions.pcWarrantyAndMaintenanceCost,
          currency
        )
      ).toFixed(2)
    );
    setWarrantyAndMaintenanceCost(
      (
        selectedYears *
        convertFromUSDToOther(
          tcoReportPayload.assumptions.warrantyAndMaintenanceCost,
          currency
        )
      ).toFixed(2)
    );
  }, [selectedYears, currency]);
  useEffect(() => {
    if (!tcoReportPayload.options.default) {
      setCustomAssumptionState((prev) => {
        return {
          ...prev,
          customCameraChecked: tcoReportPayload.assumptions.SurfaceCameraCost > 0,
          customLightChecked: tcoReportPayload.assumptions.SurfaceLightsCost > 0,
          customHeadsetChecked: tcoReportPayload.assumptions.SurfaceHeadsetCost > 0,
          customDockChecked: tcoReportPayload.assumptions.SurfaceDockCost > 0,
          customPenChecked: tcoReportPayload.assumptions.SurfacePenCost > 0,
          customKeyboardChecked: tcoReportPayload.assumptions.SurfaceTypeCoverCost > 0,
          customPowerSupplyChecked: tcoReportPayload.assumptions.SurfacePowerSupplyCost > 0,
        }
      });
    }
  }, [tcoReportPayload.options.default]);
  const currencyDetails = useMemo(() => {
    const currencyCode = tcoConfig.currencies.find(
      (c: Currency) => c.countryCode === selectedCountry.code
    )?.currencyCode;
    return CurrencySymbols.currencySymbols.find(
      (c: CurrencySymbol) => c.code === currencyCode
    );
  }, [selectedCountry.code, tcoConfig.currencies]);

  const baseId = useId();

  const onWantAccessoriesChange = (
    ev: React.FormEvent<HTMLDivElement>,
    data: RadioGroupOnChangeData
  ) => {
    setTcoReportPayload({
      ...tcoReportPayload,
      options: {
        ...tcoReportPayload.options,
        wantAccessories: data.value === "true",
      },
    });
  };
  const resetAssumptions = (assumptions: TcoAssumptionsPayload) => ({
    ...assumptions,
    SurfaceCameraCost: 0,
    PCCameraCost: 0,
    SurfaceLightsCost: 0,
    PCLightsCost: 0,
    SurfaceHeadsetCost: 0,
    PCHeadsetCost: 0,
    SurfaceDockCost: 0,
    PCDockCost: 0,
    SurfacePenCost: 0,
    PCPenCost: 0,
    SurfaceTypeCoverCost: 0,
    PCTypeCoverCost: 0,
    SurfacePowerSupplyCost: 0,
    PCPowerSupplyCost: 0,
  });
  const onAssumptionsTypeChange = (
    ev: React.FormEvent<HTMLDivElement>,
    data: RadioGroupOnChangeData
  ) => {
    setTcoReportPayload((prev) => {
      const isDefault = data.value === "default";
      return {
        ...prev,
        options: {
          ...prev.options,
          default: isDefault,
          custom: data.value === "custom",
        },
        assumptions: isDefault
          ? resetAssumptions(prev.assumptions)
          : prev.assumptions,
      };
    });
  };

  const defaultAssumptionsTooltips = () => {
    return (
      <>
        <Text>
          Based on IDC's study, "
          <a href={BUSINESS_CASE_URL} rel="noreferrer" target="_blank">
            Evaluating the Business Case of Microsoft Surface
          </a>
          ", commissioned by Microsoft.
        </Text>
        <Text>
          {`The average organization spends ${formatCurrency(
            344 * tcoReportPayload.currencyConversionMultipleFromUSD,
            currencyDetails ? currencyDetails?.symbol : "$",
            0
          )} in accessories per device.`}
        </Text>
        <Text>
          {"Default assumptions include the camera, lights, headset and dock."}
        </Text>
      </>
    );
  };

  const customAssumptionTooltip = () => {
    return (
      <>
        <Text>
          Based on IDC's study, "
          <a href={BUSINESS_CASE_URL} rel="noreferrer" target="_blank">
            Evaluating the Business Case of Microsoft Surface
          </a>
          ", commissioned by Microsoft.
        </Text>
        <Text>
          Pick your own accessories, and input the corresponding costs for
          Surface and the alternative PC.
        </Text>
      </>
    );
  };
  const sameAccessoryCostCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    data: CheckboxOnChangeData
  ) => {
    setCustomAssumptionState({
      ...customAssumptionState,
      sameAccessoryCostChecked: data.checked === true,
    });
  };

  const convertOtherToUsd = (currencyValue: number) => {
    if (currencyDetails?.code !== "USD") {
      currencyValue = convertFromOtherToUSD(currencyValue, currency);
    }
    return currencyValue;
  };
  const onWarrantyAndMaintenanceCostChange = (
    cost: string,
    key: "pcWarrantyAndMaintenanceCost" | "warrantyAndMaintenanceCost"
  ) => {
    let value = parseFloat(cost || "0");
    if (value > 0) {
      value = parseFloat((convertOtherToUsd(value) / selectedYears).toFixed(2));
    }
    setTcoReportPayload({
      ...tcoReportPayload,
      assumptions: {
        ...tcoReportPayload.assumptions,
        [key]: value,
      },
    });
  };

  const onPcWarrantyAndMaintenanceCostChange = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    onWarrantyAndMaintenanceCostChange(
      pcWarrantyAndMaintenanceCost,
      "pcWarrantyAndMaintenanceCost"
    );
  };

  const onSurfaceWarrantyAndMaintenanceCostChange = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    onWarrantyAndMaintenanceCostChange(
      warrantyAndMaintenanceCost,
      "warrantyAndMaintenanceCost"
    );
  };

  const defaultAssumptionsCheckboxChange = (checked: boolean, key: string) => {
    setTcoReportPayload({
      ...tcoReportPayload,
      options: {
        ...tcoReportPayload.options,
        [key]: checked,
      },
    });
  };
  return (
    <Stack
      className={style.extenedWarrantyMainWrapper}
      tokens={{ childrenGap: 24 }}
      styles={{
        root: {
          marginTop: "16px",
        },
      }}
    >
      <Stack>
        <Field label="Would you like to purchase accessories with your device?">
          <RadioGroup
            layout="horizontal"
            value={tcoReportPayload.options.wantAccessories ? "true" : "false"}
            onChange={onWantAccessoriesChange}
            name="wantAccessory"
          >
            <Radio value="true" label="Yes" />
            <Radio value="false" label="No" />
          </RadioGroup>
        </Field>
      </Stack>
      {tcoReportPayload.options.wantAccessories && (
        <Stack>
          <StackItem>
            <Field label="Would you like to use the default or custom assumptions?">
              <RadioGroup
                layout="horizontal"
                value={tcoReportPayload.options.default ? "default" : "custom"}
                name="DefaultOrCustom"
                onChange={onAssumptionsTypeChange}
              >
                <Radio value="default" label="Default" />
                <Radio value="custom" label="Custom" />
              </RadioGroup>
            </Field>
          </StackItem>
          {tcoReportPayload.options.default ? (
            <Stack
              verticalFill
              styles={{ root: { marginTop: "24px !important" } }}
            >
              <InfoLabel
                size={"medium"}
                info={defaultAssumptionsTooltips()}
                className={style.tooltipStyle}
              >
                Default Assumptions
              </InfoLabel>
              <Checkbox
                label={`${formatCurrency(
                  63 * tcoReportPayload.currencyConversionMultipleFromUSD,
                  currencyDetails?.symbol ?? "$",
                  0
                )} savings per device on peripheral cameras`}
                checked={
                  tcoReportPayload.options.includeCameraPeripheralSavings
                }
                onChange={(_, data) => {
                  defaultAssumptionsCheckboxChange(
                    data.checked as boolean,
                    "includeCameraPeripheralSavings"
                  );
                }}
              />
              <Checkbox
                label={`${formatCurrency(
                  30 * tcoReportPayload.currencyConversionMultipleFromUSD,
                  currencyDetails?.symbol ?? "$",
                  0
                )} savings per device on peripheral lights for meetings`}
                checked={
                  tcoReportPayload.options.includeLightsPeripheralSavings
                }
                onChange={(_, data) => {
                  defaultAssumptionsCheckboxChange(
                    data.checked as boolean,
                    "includeLightsPeripheralSavings"
                  );
                }}
              />
              <Checkbox
                label={`${formatCurrency(
                  27 * tcoReportPayload.currencyConversionMultipleFromUSD,
                  currencyDetails?.symbol ?? "$",
                  0
                )} savings per device on a headset`}
                checked={
                  tcoReportPayload.options.includeHeadsetPeripheralSavings
                }
                onChange={(_, data) => {
                  defaultAssumptionsCheckboxChange(
                    data.checked as boolean,
                    "includeHeadsetPeripheralSavings"
                  );
                }}
              />
              <Checkbox
                label={`${formatCurrency(
                  0 * tcoReportPayload.currencyConversionMultipleFromUSD,
                  currencyDetails?.symbol ?? "$",
                  0
                )} savings per device on a dock`}
                checked={tcoReportPayload.options.includeDockCost}
                onChange={(_, data) => {
                  defaultAssumptionsCheckboxChange(
                    data.checked as boolean,
                    "includeDockCost"
                  );
                }}
              />
            </Stack>
          ) : (
            <Stack
              tokens={{ childrenGap: 8 }}
              verticalFill
              styles={{ root: { marginTop: "16px !important" } }}
            >
              <InfoLabel
                size={"medium"}
                info={customAssumptionTooltip()}
                className={style.tooltipStyle}
              >
                Custom Assumptions
              </InfoLabel>

              <Stack>
                <Checkbox
                  label={`Select if accessories costs are the same for both Surface and the Alternative PC`}
                  checked={customAssumptionState.sameAccessoryCostChecked}
                  onChange={sameAccessoryCostCheckboxChange}
                />
              </Stack>
              {customAssumptionsItems.map((item: any, index: number) => (
                <CustomAssumptionsItem
                  key={`${index}`}
                  customAssumptionState={customAssumptionState}
                  setCustomAssumptionState={setCustomAssumptionState}
                  item={item}
                />
              ))}
            </Stack>
          )}
        </Stack>
      )}
      <Stack className={style.extendedWarrantyFooter}>
        <Label>
          How much will your Extended Warranty and Maintenance Plan for the
          alternative device cost over {selectedYears} years?
        </Label>
        <Input
          id={`${baseId}-extended-warranty-other-cost`}
          contentBefore={
            <Text
              size={300}
              id={`${baseId}-extended-warranty-cost-other-currency`}
            >
              {currencyDetails?.symbol}
            </Text>
          }
          step="0.01"
          value={formatNumber(pcWarrantyAndMaintenanceCost)}
          onChange={(_, data) => {
            let value = data.value;
            value = value.replaceAll(",", "");
            if (validateDecimal(value)) {
              setPcWarrantyAndMaintenanceCost(value);
            }
          }}
          onBlur={onPcWarrantyAndMaintenanceCostChange}
        />
      </Stack>
      <Stack className={style.extendedWarrantyFooter}>
        <Label>
          How much will your Extended Warranty and Maintenance Plan for your
          Surface devices cost over {selectedYears} years?
        </Label>
        <Input
          id={`${baseId}-extended-warranty-cost`}
          contentBefore={
            <Text size={300} id={`${baseId}-extended-warranty-cost-currency`}>
              {currencyDetails?.symbol}
            </Text>
          }
          step="0.01"
          value={formatNumber(warrantyAndMaintenanceCost)}
          onChange={(_, data) => {
            let value = data.value;
            value = value.replaceAll(",", "");
            if (validateDecimal(value)) {
              setWarrantyAndMaintenanceCost(value);
            }
          }}
          onBlur={onSurfaceWarrantyAndMaintenanceCostChange}
        />
      </Stack>
    </Stack>
  );
};
export default AccessoriesAndExtendedWarranty;
