import { Stack } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { styles } from "./commonStyles";
import { InfoLabel } from "@fluentui/react-components";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import LabelledInput, { Tooltip } from "../common/LabelledInput";
import { AdvancedInput, AdvancedInputItem } from "v2/types/advancedInput";
import tooltipData from "v2/store/tooltipData.json";
import {
  convertFromOtherToUSD,
  convertFromUSDToOther,
} from "v2/utilities/currencyConverter";
import { convertStringToNumber } from "utils/helpers";

const DeviceSavings: FC = () => {
  const { tcoCompleteReport, setTcoReportPayload, currency } =
    useTcoCalculationContext();
  const [deviceSavings, setDeviceSavings] = useState<Partial<AdvancedInput>>(
    {}
  );

  const getTcoReportData = () => {
    const totalSavings = tcoCompleteReport.totalSavings.rows;
    if (!totalSavings) return deviceSavings;

    let data: Partial<AdvancedInput> = { ...deviceSavings };

    totalSavings.forEach((row) => {
      if (row.label === "Increased Residual Value") {
        data.increasedResidualValue = {
          ...data.increasedResidualValue,
          checked: true,
          surfaceValue: row.surfaceValue.toString(),
          pcValue: row.pcValue.toString(),
          value: row.surfaceValue.toString(),
        } as AdvancedInputItem;
      } else if (row.label === "Reduced Support") {
        data.reducedSupport = {
          ...data.reducedSupport,
          checked: true,
          surfaceValue: row.surfaceValue.toString(),
          pcValue: row.pcValue.toString(),
          value: row.surfaceValue.toString(),
        } as AdvancedInputItem;
      }
    });

    return data;
  };

  useEffect(() => {
    const data = getTcoReportData();
    setDeviceSavings(data);
  }, []);

  useEffect(() => {
    const convert = (value: string) => {
      if (currency.countryCode === "US") return value;
      return convertFromUSDToOther(
        convertStringToNumber(value),
        currency
      ).toString();
    };
    const advancedInputs = getTcoReportData();
    if (!advancedInputs) return;
    setDeviceSavings((prev) => {
      const updatedState = { ...prev };
      Object.keys(prev).forEach((key) => {
        const itemKey = key as keyof AdvancedInput;
        const item = prev[itemKey];
        if (item) {
          updatedState[itemKey] = {
            ...item,
            surfaceValue: convert(
              advancedInputs?.[itemKey]?.surfaceValue ?? ""
            ),
            pcValue: convert(advancedInputs?.[itemKey]?.pcValue ?? ""),
            value: convert(advancedInputs?.[itemKey]?.surfaceValue ?? ""),
          };
        }
      });
      return updatedState;
    });
  }, [currency]);

  const onChange = (key: string, subLabel: string, value: string) => {
    setDeviceSavings((prev) => {
      if (!prev) {
        return prev;
      }
      const updatedValue = value;
      const keyToUpdate =
        subLabel === "Surface value" ? "surfaceValue" : "pcValue";
      const changeFlag =
        subLabel === "Surface value"
          ? "isSurfaceValueChanged"
          : "isOtherPCValueChanged";
      const currentKey = key as keyof AdvancedInput;
      const updatedState = {
        ...prev,
        [currentKey]: {
          ...prev[currentKey],
          [keyToUpdate]: updatedValue,
          [changeFlag]: true,
          value:
            subLabel === "Surface value"
              ? updatedValue
              : prev[currentKey]?.value,
        },
      };
      return updatedState;
    });
  };

  const onBlur = (inputKey: string) => {
    const key = inputKey as keyof AdvancedInput;
    const convert = (value: string) => {
      if (currency.countryCode === "US") return value;
      return convertFromOtherToUSD(
        convertStringToNumber(value),
        currency
      ).toString();
    };
    if (deviceSavings && deviceSavings[key]) {
      let updatedData = { ...deviceSavings[key] };
      if (!updatedData) return;
      updatedData = {
        ...updatedData,
        value: convert(updatedData.surfaceValue ?? "0"),
        surfaceValue: convert(updatedData.surfaceValue ?? "0"),
        pcValue: convert(updatedData.pcValue ?? "0"),
      };
      setTcoReportPayload((prev) => ({
        ...prev,
        advancedInput: {
          ...prev?.advancedInput,
          [key]: updatedData,
        },
      }));
    }
  };

  const getInfo = (info: string) => {
    return <Stack>{info}</Stack>;
  };

  const deviceSavingsData = [
    {
      label: "Increased Residual Value",
      info: getInfo(tooltipData.increasedResidualValue),
      subLabels: ["Surface value", "Other PC value"],
      key: "increasedResidualValue",
      data: deviceSavings?.increasedResidualValue,
    },
    {
      label: "Reduced Support",
      info: "Of the total respondents surveyed, 25 percent who used Surface were able to retire or avoid purchasing security solutions or third-party services. This context included a one-time cost of $2 for deployment-related support, an annual cost of $5 for ongoing management, and an annual cost of $14 for security solutions or services per device. This translates to a three-year benefit of $58. (Refer to IDC study.)",
      subLabels: ["Surface value", "Other PC value"],
      key: "reducedSupport",
      data: deviceSavings?.reducedSupport,
    },
  ];

  return (
    <Stack className={styles.childContainer} tokens={{ childrenGap: 16 }}>
      {deviceSavingsData?.map((item) => {
        return (
          <Stack key={item.label} tokens={{ childrenGap: 8 }}>
            <InfoLabel
              info={item.info}
              style={{ fontWeight: 600 }}
              className={Tooltip.tooltipStyle}
            >
              {item.label}
            </InfoLabel>
            <Stack horizontal tokens={{ childrenGap: 16 }}>
              {item.subLabels?.map((subLabel) => (
                <LabelledInput
                  key={subLabel}
                  label={subLabel}
                  value={
                    subLabel === "Surface value"
                      ? `${item.data?.surfaceValue}`
                      : `${item.data?.pcValue}`
                  }
                  isAmount={true}
                  onChange={(ev, data) => {
                    onChange(item.key, subLabel, data.value);
                  }}
                  onBlur={() => onBlur(item.key)}
                />
              ))}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default DeviceSavings;
