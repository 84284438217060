import { mergeStyleSets, Stack } from "@fluentui/react";
import {
    InfoLabel,
    Text,
    Link,
    Checkbox,
    Label,
    Input,
} from "@fluentui/react-components";
import { useEffect, useId, useState } from "react";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import {
    Sustainability as SustainabilityType,
    SustainabilityCategory,
} from "v2/types/apiPayloadTypes";
import { convertFromOtherToUSD, convertFromUSDToOther, validateDecimal } from "v2/utilities/currencyConverter";
import { formatNumber } from "v2/utilities/helper";

const sustainabilityStyle = mergeStyleSets({
    tooltipStyle: {
        "&& .fui-PopoverSurface": {
            padding: "0.7rem 1.5rem",
            width: 380,
            maxWidth: "90%",
            span: {
                display: "block",
                paddingBottom: "0.7rem",
            },
        },
    },
    inputBackground: {
        background: "#F0F0F0 !important",
    },
});

const Sustainability: React.FC = () => {
    const { tcoReportPayload, setTcoReportPayload, currency } = useTcoCalculationContext();
    const [sustainabilityState, setSustainabilityState] = useState({
        Energy: {
            ...tcoReportPayload.sustainability.Energy,
            unitPrice: convertFromUSDToOther(tcoReportPayload.sustainability.Energy.unitPrice, currency).toString(),
            surfaceValue: tcoReportPayload.sustainability.Energy.surfaceValue.toString(),
            otherPcValue: tcoReportPayload.sustainability.Energy.otherPcValue.toString(),
        },
        CarbonEmissions: {
            ...tcoReportPayload.sustainability.CarbonEmissions,
            unitPrice: convertFromUSDToOther(tcoReportPayload.sustainability.CarbonEmissions.unitPrice, currency).toString(),
            surfaceValue: tcoReportPayload.sustainability.CarbonEmissions.surfaceValue.toString(),
            otherPcValue: tcoReportPayload.sustainability.CarbonEmissions.otherPcValue.toString(),
        },
    });
    const baseId = useId();
    useEffect(() => {
        const convertSustainabilityState = (sustainability: any) => {
            return {
                Energy: {
                    ...sustainability.Energy,
                    unitPrice: convertFromUSDToOther(sustainability.Energy.unitPrice, currency).toString(),
                },
                CarbonEmissions: {
                    ...sustainability.CarbonEmissions,
                    unitPrice: convertFromUSDToOther(sustainability.CarbonEmissions.unitPrice, currency).toString(),
                },
            };
        };

        setSustainabilityState(convertSustainabilityState(tcoReportPayload.sustainability));
    }, [currency]);

    const energyCostTooltip = () => {
        return (
            <Text>
                Surface can help with savings through sustainability. Surface devices
                are designed for low energy usage which translates to cost savings as
                well as lower environmental impact. To account for this in your TCO,
                please enter your energy usage per device (Kwh) by visiting the {' '}
                <Link href="/sustainability/calculator" target="_blank" rel="noopener noreferrer">
                    Surface Emissions Estimator
                </Link>{' '}
                or visit EnergyStar.
            </Text>
        );
    };
    const carbonEmissionsTooltip = () => {
        return (
            <Text>
                Surface can help with savings through sustainability. Surface devices
                are designed for low carbon impact which translates to cost savings as
                well as lower environmental impact. To account for this in your TCO,
                please enter your Co2 per device by visiting the{' '}
                <Link href="/sustainability/calculator" target="_blank" rel="noopener noreferrer">
                    Surface Emissions Estimator
                </Link>{' '}
                or visit your current device manufacturer website to find this
                information.
            </Text>
        );
    };
    const sustainabilityData = {
        energyCostData: [
            {
                key: "unitPrice",
                label: "Kwh price",
                isAmount: true,
            },
            {
                key: "surfaceValue",
                label: "Surface",
                isAmount: false,
            },
            {
                key: "otherPcValue",
                label: "Other PC",
                isAmount: false,
            },
        ],
        carbonEmissionData: [
            {
                key: "unitPrice",
                label: "Kwh price",
                isAmount: true,
            },
            {
                key: "surfaceValue",
                label: "Surface",
                isAmount: false,
            },
            {
                key: "otherPcValue",
                label: "Other PC",
                isAmount: false,
            },
        ]
    };

    const onBlur = (
        ev: React.FocusEvent<HTMLInputElement, Element>,
        key: string,
        category: 'Energy' | 'CarbonEmissions'
    ) => {
        ev.preventDefault();
        const value = ev.target.value.replaceAll(",", "") || "0";
        if (value !== "") {
            const updatedValue = key === "unitPrice" ? convertFromOtherCurrencyToUSD(Number(value)) : Number(value);
            setTcoReportPayload((prev) => ({
                ...prev,
                sustainability: {
                    ...prev.sustainability,
                    [category]: {
                        ...prev.sustainability[category],
                        [key]: updatedValue,
                    },
                },
            }));
            if (sustainabilityState[category][key as keyof SustainabilityCategory] === "") {
                setSustainabilityState((prev) => ({
                    ...prev,
                    [category]: {
                        ...prev[category],
                        [key]: value,
                    },
                }));
            }
        }
    };

    const convertFromOtherCurrencyToUSD = (currencyValue: number) => {
        if (currency.countryCode === "US") {
            return currencyValue;
        }
        return convertFromOtherToUSD(currencyValue, currency);
    }

    return (
        <Stack
            styles={{ root: { padding: "0px 0px 0px 50px", marginTop: "16px" } }}
            tokens={{ childrenGap: 16 }}
        >
            <Stack tokens={{ childrenGap: 8 }}>
                <InfoLabel
                    size={"medium"}
                    info={energyCostTooltip()}
                    className={sustainabilityStyle.tooltipStyle}
                >
                    What is the average energy cost per Kwh and annual energy usage per
                    device?
                </InfoLabel>
                <Stack grow horizontal tokens={{ childrenGap: 16 }} verticalAlign="end">
                    <Checkbox
                        id={`${baseId}-energy-cost`}
                        checked={tcoReportPayload.sustainability.Energy.checked}
                        onChange={(_, data) => {
                            setTcoReportPayload({
                                ...tcoReportPayload,
                                sustainability: {
                                    ...tcoReportPayload.sustainability,
                                    Energy: {
                                        ...tcoReportPayload.sustainability.Energy,
                                        checked: data.checked as boolean,
                                    },
                                },
                            });
                        }}
                    />
                    {sustainabilityData.energyCostData?.map((item) => (
                        <Stack verticalFill grow styles={{ root: { maxWidth: "29%" } }} key={item.key}>
                            <Label>{item.label}</Label>
                            <Input
                                id={`${baseId}-energy-cost-${item.key}`}
                                disabled={!tcoReportPayload.sustainability.Energy.checked}
                                contentBefore={item.isAmount ? <Text>{currency?.symbol}</Text> : ""}
                                className={`${!tcoReportPayload.sustainability.Energy.checked ? sustainabilityStyle.inputBackground : ''}`}
                                contentAfter={!item.isAmount ? <Text>Kwh</Text> : ""}
                                value={formatNumber(`${sustainabilityState.Energy[item.key as keyof SustainabilityCategory]}`)}
                                onChange={(_, data) => {
                                    const value = data.value.replaceAll(",", "");
                                    if (validateDecimal(value)) {
                                        setSustainabilityState((prev) => ({
                                            ...prev,
                                            Energy: {
                                                ...prev.Energy,
                                                [item.key]: value,
                                            },
                                        }));
                                    }
                                }}
                                onBlur={(ev) => onBlur(ev, item.key, "Energy")}
                                step={"0.01"}
                            />
                        </Stack>
                    ))}

                </Stack>

            </Stack>
            <Stack tokens={{ childrenGap: 8 }}>
                <InfoLabel
                    size={"medium"}
                    info={carbonEmissionsTooltip()}
                    className={sustainabilityStyle.tooltipStyle}
                >
                    What is your cost per Metric ton of Co2 and Co2 value per device?
                </InfoLabel>
                <Stack grow horizontal tokens={{ childrenGap: 16 }} verticalAlign="end">
                    <Checkbox
                        id={`${baseId}-carbon-emissions`}
                        checked={tcoReportPayload.sustainability.CarbonEmissions.checked}
                        onChange={(_, data) => {
                            setTcoReportPayload({
                                ...tcoReportPayload,
                                sustainability: {
                                    ...tcoReportPayload.sustainability,
                                    CarbonEmissions: {
                                        ...tcoReportPayload.sustainability.CarbonEmissions,
                                        checked: data.checked as boolean,
                                    },
                                },
                            });
                        }}
                    />
                    {sustainabilityData.carbonEmissionData?.map((item) => (
                        <Stack verticalFill grow styles={{ root: { maxWidth: "29%" } }} key={item.key}>
                            <Label>{item.label}</Label>
                            <Input
                                id={`${baseId}-carbon-emissions-${item.key}`}
                                disabled={!tcoReportPayload.sustainability.CarbonEmissions.checked}
                                contentBefore={item.isAmount ? <Text>{currency?.symbol}</Text> : ""}
                                className={`${!tcoReportPayload.sustainability.CarbonEmissions.checked ? sustainabilityStyle.inputBackground : ''}`}
                                contentAfter={!item.isAmount ? <Text>Kwh</Text> : ""}
                                value={formatNumber(`${sustainabilityState.CarbonEmissions[item.key as keyof SustainabilityCategory]}`)}
                                onChange={(_, data) => {
                                    const value = data.value.replaceAll(",", "");
                                    if (validateDecimal(value)) {
                                        setSustainabilityState((prev) => ({
                                            ...prev,
                                            CarbonEmissions: {
                                                ...prev.CarbonEmissions,
                                                [item.key]: value,
                                            },
                                        }));
                                    }
                                }}
                                onBlur={(ev) => onBlur(ev, item.key, "CarbonEmissions")}
                                step={"0.01"}
                            />
                        </Stack>
                    ))}

                </Stack>
            </Stack>
        </Stack>
    );
};

export default Sustainability;
