import { useRef, useMemo, useEffect, useState } from "react";

import { DelayedRender, Text } from "@fluentui/react";

import { useBoolean, useId } from "@fluentui/react-hooks";

import {
  Stack,
  mergeStyleSets,
  FontIcon,
  Callout,
  DirectionalHint,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import {
  PIE_CHART_OPTIONS,
  STACKED_BAR_CHART_OPTIONS,
  TCO_REPORT_CHART_COLOR,
  BAR_CHART_OPTIONS,
  ASSUMPTION_ADVANCED_LIST,
  BAR_COMPARISON_CHART_OPTIONS,
  TCO_SIMPLE_REPORT_CHART_COLOR,
} from "constants/tco";

import {
  formatNumberWithUnit,
  isSmallWidthScreen,
  // downloadPdf,
  isObjectEmpty,
  formatCurrency,
  convertStringToNumber,
  convertNumberToString,
} from "utils/helpers";
import BackToTop from "components/BackToTop";
import ReactEcharts from "components/ReactEcharts";
import ReadMore from "components/ReadMore";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import ChartDisclaimer from "./ChartDisclaimer";
import "./TcoCompleteReport.scss";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import { Button } from "@fluentui/react-components";
import { StackItem } from "@fluentui/react";
import ReportDescription from "v2/components/report-page/ReportDescription";
import ReportLayout from "v2/components/report-page/ReportLayout";
import CostSavingsBanner from "v2/components/cost-savings-banner/CostSavingsBanner";
import successStories from "v2/store/successStories.json";
import { updateTcoCompleteReport } from "v2/utilities/reportHelpers";
import { useNavigate } from "react-router-dom";
import tooltipData from "../../store/tooltipData.json";
import ReportTitle from "v2/components/report-page/ReportTitle";
import AdditionalLinks from "v2/components/report-page/AdditionalLinks";
import { numberToWords } from "v2/utilities/helper";
import { BUSINESS_CASE_URL } from "v2/constants";

export const commonStyles = mergeStyleSets({
  container: {
    paddingBottom: "10px",
    width: "100%",
    padding: "40px 0 40px 40px",
  },
});

const standardStyles = mergeStyleSets({
  container: {
    paddingRight: "2rem",
  },
  section: {
    paddingTop: "1.214rem",
  },
  sectiontitle: {
    textTransform: "capitalize",
    fontSize: "1.25rem",
    fontWeight: 600,
    lineHeight: "1.25rem",
    marginBottom: "1.625rem",
  },
  topSectionWrapper: {
    margin: "5vh 0",
    gap: "2rem",
  },
  topSectionLeft: {
    padding: "1.5rem 0",
    "> div:first-child": {
      marginBottom: "2rem",
    },
  },
  topSectionLeftRow: {
    alignItems: "center",
    color: "#0078D4",
  },
  topSectionLeftLabel: {
    width: "12rem",
    fontSize: "1rem",
    lineHeight: "1.25rem",
  },
  topSectionValue: {
    fontSize: "2rem",
    fontWeight: 600,
    lineHeight: "3rem",
  },
  chartRowWrapper: {
    marginLeft: "4%",
    textAlign: "center",
    justifyContent: "flex-end",
    alignItems: "center",
    "> div:nth-child(1)": {
      width: "32%",
    },
    "> div:nth-child(2)": {
      width: "30%",
    },
    "> div:nth-child(3)": {
      width: "32%",
    },
  },
  xaxisLabelWrapper: {
    "> div:nth-child(1)": {
      width: "30%",
    },
    "> div:nth-child(2)": {
      width: "32.5%",
    },
    "> div:nth-child(3)": {
      width: "30%",
    },
  },
  sumValueWrapper: {
    // marginBottom: "0.5rem",
  },
  xaxisLabel: {
    fontSize: "0.75rem",
    lineHeight: "0.875rem",
    gap: "1.8rem",
    justifyContent: "center",
  },
  topSectionRight: {
    padding: "1.5rem",
    color: "#107C10",
    gap: "2rem",
  },
  mobileChartWrapper: {
    marginBottom: "1rem",
  },
  mobileChartValue: {
    fontSize: "2rem",
    fontWeight: 600,
    lineHeight: "1.6rem",
    color: "#0078D4",
    textAlign: "center",
    marginBottom: "1rem",
    marginLeft: "2rem",
  },
  summaryItem: {
    textAlign: "center",
    height: "fit-content",
    h2: {
      color: "#0078D4",
      marginTop: "0",
      marginBottom: "0",
      fontSize: "2.14rem",
      fontWeight: 600,
    },
    p: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textTransform: "uppercase",
      color: "#0078D4",
      fontSize: "1.28rem",
      fontWeight: 600,
      marginTop: "0",
      marginBottom: "0",
    },
    span: {
      textTransform: "uppercase",
      color: "#0078D4",
      display: "block",
      fontSize: "0.857rem",
      fontWeight: 400,
    },
  },
  summaryContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    paddingBottom: "0.5rem",
    "div:nth-child(-n+2) p": {
      fontWeight: 600,
    },
  },
  summaryWrapper: {
    width: "100%",
    paddingTop: "1.428rem",
  },
  summaryTitle: {
    textTransform: "capitalize",
    fontSize: "1.428rem",
    fontWeight: 600,
    color: "#0078D4",
    lineHeight: "0.1rem",
    marginBottom: "1.495rem",
    textAlign: "center",
  },
  titleWrapper: {
    display: "flex",
    gap: "0.625rem",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "1.25rem",
    color: "#0078D4",
    textTransform: "uppercase",
  },
  infoIcon: {
    cursor: "pointer",
    fontSize: "1.25rem",
    color: "#201F1E",
  },
  titleWrapperMobile: {
    marginBottom: "1rem",
  },
  summaryLinesWrapper: {
    display: "flex",
    "> div:nth-child(1)": {
      flex: 1,
    },
    "> div:nth-child(2)": {
      width: "22%",
    },
    "> div:nth-child(3)": {
      flex: 1,
    },
  },
  summaryLine: {
    height: "1px",
    borderBottom: "1px solid #0078D4",
  },
  infoIconUpdated: {
    cursor: "pointer",
    fontSize: "1.25rem",
    color: "#0078D4",
    paddingLeft: "0.5rem",
  },
});

export const Buttonstyle = mergeStyleSets({
  backButton: {
    padding: "8px 16px !important",
    gap: "var(--Font-size-100, 10px)",
    borderRadius: "2px",
    borderColor: "#000 !important",
    minWidth: "0px !important",
  },
  tcoButton: {
    padding: "8px 16px !important",
  },
  assumptionsButton: {
    paddingLeft: "0px !important",
    color: "rgb(0, 120, 212) !important",
  },
});

const calloutStyles = mergeStyleSets({
  callout: {
    padding: "0.7rem 1.5rem",
    width: 380,
    maxWidth: "90%",
    span: {
      display: "block",
      paddingBottom: "0.7rem",
    },
  },
  calloutAlternative: {
    padding: "0rem 1.5rem",
    width: 150,
    maxWidth: "90%",
  },
  calloutForSummaryItems: {
    padding: "1rem 1.5rem",
    width: 500,
    maxWidth: "90%",
    span: {
      display: "block",
      paddingBottom: "0.7rem",
    },
  },
  tooltipAlign: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const TcoCompleteReport = (props) => {
  let {
    data,
    dataPerDevice,
    isLoading,
    advancedInput,
    currencySymbol,
    userInput,
  } = props;
  let [reportState, setReportState] = useState({});
  let [completeReport, setCompleteReport] = useState({});

  const [totalValues, setTotalValues] = useState({
    totalCosts: "0",
    deviceSavings: "0",
    itSimplification: "0",
    employeeEmpowerment: "0",
    sustainability: "0",
  });

  const navigate = useNavigate();

  let [chatbotExpanded, setchatbotExpanded] = useState({});

  const [simpleReport, setSimpleReport] = useState({});

  const summaryNPVId = useId("npv-id");
  const [isDeviceCalloutVisible, { toggle: toggleDeviceCalloutVisible }] =
    useBoolean(false);
  const [isITCalloutVisible, { toggle: toggleITCalloutVisible }] =
    useBoolean(false);
  const [isEmployeeCalloutVisible, { toggle: toggleEmployeeCalloutVisible }] =
    useBoolean(false);
  const [isNPVCalloutVisible, { toggle: toggleIsNPVCalloutVisible }] =
    useBoolean(false);
  const [isPaybackCalloutVisible, { toggle: toggleIsPaybackCalloutVisible }] =
    useBoolean(false);
  const [savings, setSavings] = useState([
    "0\nSavings",
    "0\nSavings",
    "0\nSavings",
  ]);

  const [discountRate, setDiscountRate] = useState({
    pbp: 6.2,
    npv: 6.2,
  });

  const summaryPaybackId = useId("payback-id");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let {
    setTcoReportPayload,
    currency,
    tcoCompleteReport,
    tcoReportPayload,
    tcoReport,
    selectedCountry,
    selectedYears,
    customerInfo,
  } = useTcoCalculationContext();

  data = tcoCompleteReport;
  let apiData = data;
  const currencyConversionMultipleFromUSD =
    tcoReportPayload.currencyConversionMultipleFromUSD;

  const convertCategoryObject = (category) => {
    if (!category) return {};
    return {
      ...category,
      pcValue: category.pcValue * currencyConversionMultipleFromUSD,
      surfaceValue: category.surfaceValue * currencyConversionMultipleFromUSD,
      savings: category.savings * currencyConversionMultipleFromUSD,
      rows: convertRowsArray(category.rows),
    };
  };

  const convertRowsArray = (rowsArray) => {
    return rowsArray.map((i) => {
      return {
        ...i,
        pcValue: i.pcValue * currencyConversionMultipleFromUSD,
        savings: i.savings * currencyConversionMultipleFromUSD,
        surfaceValue: i.surfaceValue * currencyConversionMultipleFromUSD,
      };
    });
  };

  const industry = successStories.industries.find(
    (industry) => industry.name === customerInfo.industry
  );

  apiData = {
    ...apiData,
    costs: apiData.costs * currencyConversionMultipleFromUSD,
    savings: apiData.savings * currencyConversionMultipleFromUSD,
    employeeEmpowerment: convertCategoryObject(apiData.employeeEmpowerment),
    itSimplification: convertCategoryObject(apiData.itSimplification),
    totalCosts: convertCategoryObject(apiData.totalCosts),
    totalSavings: convertCategoryObject(apiData.totalSavings),
    sustainability: convertCategoryObject(apiData.sustainability),
  };

  isLoading = false;
  advancedInput = tcoReportPayload?.advancedInput ?? {};
  currencySymbol = currency?.symbol ?? "$";
  userInput = tcoReportPayload ?? {};

  const advancedData = useMemo(() => {
    let result = {};
    const getSum = (key) => {
      return ASSUMPTION_ADVANCED_LIST[key].reduce((acc, curr) => {
        let result = acc;
        if (
          tcoCompleteReport[key].rows.find((i) => i.label == curr.reportLabel)
        ) {
          let u = tcoCompleteReport[key].rows.find(
            (i) => i.label == curr.reportLabel
          );
          if (
            key === "totalSavings" ||
            key === "itSimplification" ||
            key === "employeeEmpowerment" ||
            key === "sustainability"
          ) {
            result =
              selectedCountry.code === "US"
                ? acc + Math.round(convertStringToNumber(u.savings))
                : acc +
                  Math.round(
                    convertStringToNumber(u.savings) *
                      currencyConversionMultipleFromUSD
                  );
          } else {
            result =
              selectedCountry.code === "US"
                ? acc + Math.round(convertStringToNumber(u.surfaceValue))
                : acc +
                  Math.round(
                    convertStringToNumber(u.surfaceValue) *
                      currencyConversionMultipleFromUSD
                  );
          }
        }
        return result;
      }, 0);
    };

    if (!isObjectEmpty(tcoCompleteReport)) {
      result.totalCosts = getSum("totalCosts");
      result.deviceSavings = getSum("totalSavings");
      result.itSimplification = getSum("itSimplification");
      result.employeeEmpowerment = getSum("employeeEmpowerment");
      result.sustainability = getSum("sustainability");
      result.totalSavings =
        result.deviceSavings +
        result.itSimplification +
        result.employeeEmpowerment +
        result.sustainability;
      result.roi =
        result.totalCosts === 0
          ? 0
          : (result.totalSavings / result.totalCosts) * 100;
    }

    setTotalValues({
      totalCosts: convertNumberToString(result.totalCosts),
      deviceSavings: convertNumberToString(result.deviceSavings),
      itSimplification: convertNumberToString(result.itSimplification),
      employeeEmpowerment: convertNumberToString(result.employeeEmpowerment),
      sustainability: convertNumberToString(result.sustainability || 0),
    });
    return result;
  }, [currency, tcoCompleteReport]);

  const calculateNPVAndBPB = (
    totalCost,
    totalSavings,
    type,
    view,
    discount
  ) => {
    const discountRate = discount ? Number(discount) / 100 : 0.062;
    const years = selectedYears ?? 3;
    const presentValue = totalSavings / Math.pow(1 + discountRate, years);
    const NPV = presentValue - totalCost;
    const PBP = Math.round(presentValue / totalCost) + " Months";
    return type === "NPV" ? NPV : PBP;
  };

  let detailsArray = tcoReport.details?.map((i) => {
    return {
      ...i,
      pcValue: convertNumberToString(
        (i.pcValue * currencyConversionMultipleFromUSD).toString()
      ),
      savings: convertNumberToString(
        (i.savings * currencyConversionMultipleFromUSD).toString()
      ),
      surfaceValue: (
        i.surfaceValue * currencyConversionMultipleFromUSD
      ).toString(),
    };
  });

  let apiData2 = {
    ...tcoReport,
    costs: advancedData.totalCosts,
    savings: advancedData.totalSavings,
    // costs: convertNumberToString(
    //   (Math.round(apiData.costs) * currencyConversionMultipleFromUSD).toString()
    // ),
    // savings: convertNumberToString(
    //   (Math.round(apiData.savings) * currencyConversionMultipleFromUSD).toString()
    // ),
    details: detailsArray,
  };

  let apiData3 = {
    ...tcoCompleteReport,
    costs: convertNumberToString(
      (apiData.costs * currencyConversionMultipleFromUSD).toString()
    ),
    savings: convertNumberToString(
      (apiData.savings * currencyConversionMultipleFromUSD).toString()
    ),
    details: detailsArray,
  };

  //setSimpleReport(apiData2);

  useEffect(() => {
    setSimpleReport(apiData2);
    setchatbotExpanded(false);
  }, [currency]);

  const detailReportRef = useRef(null);

  const handleButtonClick = (DivtoNavigate) => {
    navigate("/review-business-case-assumptions", {
      state: { focusDivId: DivtoNavigate },
    });
  };

  const scrollToSummary = () => {
    let el = document.getElementById("summary1");
    // el.scrollIntoView({behavior:"smooth"});
  };

  useEffect(() => {
    const savingsData = [];
    simpleReport.details?.forEach((i) => {
      savingsData.push(
        formatCurrency(i.savings, currencySymbol, 0) + "\nSavings"
      );
    });
    setSavings(savingsData);
  }, [simpleReport]);

  const costBarChartLabel = [
    {
      id: "Device",
      label: simpleReport.details ? savings[0] : "0\nSavings",
      tooltip: "Device Costs",
    },
    {
      id: "IT",
      label: simpleReport.details ? savings[1] : "0\nSavings",
      tooltip: "IT Efficiency",
    },
    {
      id: "Employee",
      label: simpleReport.details ? savings[2] : "0\nSavings",
      tooltip: "Employee Experience",
    },
  ];

  useEffect(() => {
    if (tcoCompleteReport) {
      const updatedReport = updateTcoCompleteReport(
        tcoCompleteReport,
        tcoReportPayload,
        currency,
        selectedYears
      );
      setReportState((prev) => ({
        ...prev,

        ItSimiplification: {
          reportBlock: updatedReport?.itSimplification,
          successStory: industry?.itSimplification,
        },
        employeeEmpowerment: {
          reportBlock: updatedReport?.employeeEmpowerment,
          successStory: industry?.employeeEmpowerment,
        },
        totalCosts: {
          reportBlock: updatedReport?.totalCosts,
          successStory: industry?.totalCosts,
        },
        totalSavings: {
          reportBlock: updatedReport?.totalSavings,
          successStory: industry?.totalSavings,
        },
      }));
    }
  }, [tcoCompleteReport, currency]);

  useEffect(() => {
    if (!isObjectEmpty(data)) {
      data = tcoCompleteReport;
      let apiData = data;

      apiData = {
        ...apiData,
        costs: apiData.costs * currencyConversionMultipleFromUSD,
        savings: apiData.savings * currencyConversionMultipleFromUSD,
        employeeEmpowerment: convertCategoryObject(apiData.employeeEmpowerment),
        itSimplification: convertCategoryObject(apiData.itSimplification),
        totalCosts: convertCategoryObject(apiData.totalCosts),
        totalSavings: convertCategoryObject(apiData.totalSavings),
        sustainability: convertCategoryObject(apiData.sustainability),
      };

      setCompleteReport({ ...apiData, key: uuid() });
    }
  }, [data, tcoCompleteReport, currency]);

  useEffect(() => {
    const windowWidth = window.innerWidth;
    const handleResize = () => {
      if (window.innerWidth === windowWidth) return;
      setCompleteReport((state) => {
        return {
          ...state,
          key: uuid(),
        };
      });
    };

    window.addEventListener("resize", _.debounce(handleResize, 500));
    return () => {
      window.removeEventListener("resize", _.debounce(handleResize, 500));
    };
  }, []);

  const uncheckList = useMemo(() => {
    let result = {
      totalCosts: [],
      totalSavings: [],
      itSimplification: [],
      employeeEmpowerment: [],
    };
    Object.keys(advancedInput).forEach((key) => {
      if (advancedInput[key].checked === false) {
        for (let k in ASSUMPTION_ADVANCED_LIST) {
          const uncheckItem = ASSUMPTION_ADVANCED_LIST[k].find(
            (i) => i.id === key
          );
          if (uncheckItem) {
            result[k].push(uncheckItem.reportLabel);
          }
        }
      }
    });
    return result;
  }, []);

  const chartDescriptionStyles = mergeStyleSets({
    wrapper: {
      width: "50%",
      paddingRight: "5%",
    },
    chartTitle: {
      fontSize: "1.25rem",
      fontWeight: 400,
      lineHeight: "1.625rem",
      marginTop: 0,
      marginBottom: "1rem",
      textTransform: "capitalize",
    },
    chartValueWrapper: {
      marginBottom: "2rem",
    },
    chartValue: {
      fontSize: "2.625rem",
      fontWeight: 600,
      lineHeight: "2.625rem",
      color: "#201F1E",
      marginBottom: "1rem",
    },
    chartValueText: {
      fontSize: "1rem",
      textTransform: "capitalize",
      color: "#201F1E",
    },
    chartItemList: {
      justifyContent: "space-between",
      fontSize: "1rem",
      lineHeight: "2.125rem",
    },
    chartItemName: {
      alignItems: "center",
      lineHeight: "1.125rem",
    },
    detailPointerWrapper: {
      alignItems: "center",
    },
    detail: {
      fontSize: "1.25rem",
      lineHeight: "2.5rem",
      marginRight: "0.4375rem",
    },
  });

  const costBarChartData = useMemo(() => {
    let surfaceData = {
      name: "Surface",
      value: [],
    };
    let pcData = {
      name: "Other PC",
      value: [],
    };
    simpleReport.details?.forEach((item) => {
      const currentLabel = costBarChartLabel.find((i) => {
        return item.label.includes(i.id);
      })?.tooltip;
      surfaceData.value.push({
        name: currentLabel,
        value: item.surfaceValue,
      });
      pcData.value.push({
        name: currentLabel,
        value: item.pcValue,
      });
    });
    return {
      chartData: [surfaceData, pcData],
      label: costBarChartLabel.map((i) => i.label),
      currencySymbol: currencySymbol,
    };
  }, [simpleReport, savings]);

  const getMobileCostBarChartData = (item) => {
    const currentLabel = costBarChartLabel.find((i) => {
      return item.label.includes(i.id);
    })?.tooltip;
    return {
      chartData: [
        {
          name: "Surface",
          value: [{ name: currentLabel, value: item.surfaceValue }],
        },
        {
          name: "Other PC",
          value: [{ name: currentLabel, value: item.pcValue }],
        },
      ],
      label: [currentLabel],
      currencySymbol: currencySymbol,
    };
  };

  const chartDescription = (text, data, color, button) => {
    const { title, value, readMore } = text;
    return (
      <div
        className={`${chartDescriptionStyles.wrapper} tco-report-chart-description-wrapper`}
      >
        <h3 className={chartDescriptionStyles.chartTitle} tabIndex={0}>
          {title} &nbsp; {button}
        </h3>
        {value.map((item, index) => (
          <div key={index} className={chartDescriptionStyles.chartValueWrapper}>
            <Text
              block
              className={`${chartDescriptionStyles.chartValue} tco-report-chart-description-value`}
              tabIndex={0}
            >
              {item.val}
            </Text>
            <Text
              className={`${chartDescriptionStyles.chartValueText} tco-report-chart-description-value-text`}
            >
              {item.text}
            </Text>
          </div>
        ))}
        {readMore ? (
          <ReadMore count={isSmallWidthScreen() ? 12 : 15}>{readMore}</ReadMore>
        ) : null}
        {data ? (
          <>
            {data.map((item, index) => {
              const markerColor = { backgroundColor: color[index] };
              return (
                <Stack
                  horizontal
                  key={index}
                  className={`${chartDescriptionStyles.chartItemList} tco-report-chart-item-list`}
                  tabIndex={0}
                >
                  <Stack
                    horizontal
                    className={chartDescriptionStyles.chartItemName}
                  >
                    <div
                      className={`${tableStyles.marker} tco-report-chart-item-list-marker`}
                      style={markerColor}
                    ></div>
                    <div>{item.name}</div>
                  </Stack>
                  <div>{formatCurrency(item.value, currencySymbol, 0)}</div>
                </Stack>
              );
            })}
            <Stack
              horizontal
              className={`${chartDescriptionStyles.chartItemList} tco-report-chart-item-list`}
              tabIndex={0}
            >
              <Stack
                horizontal
                className={chartDescriptionStyles.chartItemName}
              >
                <div className={`${tableStyles.marker} mobile-hide`}></div>
                <div>Total</div>
              </Stack>
              <div>
                {formatCurrency(
                  data.reduce((acc, curr) => acc + curr.value, 0),
                  currencySymbol,
                  0
                )}
              </div>
            </Stack>
          </>
        ) : null}
      </div>
    );
  };

  const tableStyles = mergeStyleSets({
    tableWrapper: {
      fontSize: "1rem",
      lineHeight: "1.25rem",
      alignItems: "center",
      marginBottom: "4.4375rem",
    },
    tableTitleWrapper: {
      justifyContent: "space-between",
    },
    tableTitle: {
      fontWeight: 600,
      width: "20%",
      lineHeight: "2.125rem",
    },
    tableTitle1: {
      width: "35%",
    },
    tableRow: {
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0.5rem 0",
      borderBottom: "1px solid #EDEBE9",
    },
    tableRow1: {
      width: "35%",
      display: "flex",
      alignItems: "center",
      fontWeight: 600,
    },
    tableRow2: {
      width: "20%",
    },
    marker: {
      display: "inline-block",
      marginLeft: "0.625rem",
      marginRight: "0.5rem",
      borderRadius: "1.25rem",
      width: "1.25rem",
      height: "1.25rem",
      flexShrink: 0,
    },
    rowSurfaceValue: {
      width: "60%",
    },
    rowValueHourWrapper: {
      width: "100%",
    },
    rowHourValue: {
      width: "40%",
    },
    withBottom: {
      borderBottom: "0.5px solid #107C10",
    },
    withoutBottom: {
      borderBottom: "unset",
    },
    totalLabel: {
      width: "35%",
      fontWeight: 600,
      paddingLeft: "2.375rem",
      boxSizing: "border-box",
    },
    greyBackground: {
      backgroundColor: "#F3F2F1",
    },
    inputWarning: {
      alignItems: "center",
      backgroundColor: "#FECABD",
      padding: "3rem 3.375rem",
      gap: "1.625rem",
    },
    infoIcon: {
      fontSize: "1.25rem",
    },
    inputWarningText: {
      fontSize: "1rem",
      lineHeight: "1.375rem",
    },
  });

  const chartDataList = (data, color, showHours) => {
    const tableTitleList = [
      {
        label: "Surfaces",
        valueKey: "surfaceValue",
        hourKey: "surfaceHours",
      },
      {
        label: "Other PCs",
        valueKey: "pcValue",
        hourKey: "pcHours",
      },
      {
        label: "Savings",
        valueKey: "savings",
        hourKey: "hoursSavings",
      },
    ];
    const getCustomizeValue = (item, key, prefix) => {
      let result = formatCurrency(item[key], prefix, 0);
      if (
        (item.isSavingsInput && key === "savings") ||
        (item.isSurfaceInput && key === "surfaceValue") ||
        (item.isOtherPcInput && key === "pcValue")
      ) {
        result = result + "*";
      }
      if (item.isSavingsInput && key !== "savings") {
        result = "----";
      }
      return result;
    };
    const hasCustomizedValue = data.rows.some(
      (i) =>
        i.isSavingsInput ||
        i.isSurfaceInput ||
        i.isOtherPcInput ||
        i.isOtherPcHoursInput
    );
    return (
      <div className={`${tableStyles.tableWrapper} tco-report-table-wrapper`}>
        <Stack
          horizontal
          className={`${tableStyles.tableTitleWrapper} tco-report-table-title mobile-hide`}
          tabIndex={0}
        >
          <div className={tableStyles.tableTitle1}></div>
          {tableTitleList.map((item, index) => (
            <Stack.Item
              key={index}
              className={`${tableStyles.tableTitle} ${
                showHours ? tableStyles.withBottom : ""
              }`}
            >
              {item.label}
            </Stack.Item>
          ))}
        </Stack>
        {data?.rows.map((item, index) => {
          const markerColor = { backgroundColor: color[index] };
          return (
            <Stack
              horizontal
              key={index}
              className={`${tableStyles.tableRow} tco-report-table-row ${
                item.savings < 0 ? tableStyles.greyBackground : ""
              }`}
              tabIndex={0}
            >
              <Stack.Item className={tableStyles.tableRow1}>
                <div className={tableStyles.marker} style={markerColor}></div>
                <div>{item.label}</div>
              </Stack.Item>
              {tableTitleList.map((i) => (
                <Stack
                  horizontal
                  className={tableStyles.tableRow2}
                  key={i.label}
                >
                  <div className="mobile-display-only tco-report-mobile-table-row-label">
                    {i.label}
                  </div>
                  {showHours ? (
                    <Stack
                      horizontal
                      className={`${tableStyles.rowValueHourWrapper} tco-report-table-value-hour-wrapper`}
                    >
                      <span className={tableStyles.rowSurfaceValue}>
                        {getCustomizeValue(item, i.valueKey, currencySymbol)}
                      </span>
                      <span className={tableStyles.rowHourValue}>
                        {i.hourKey === "pcHours" &&
                        item["pcHours"] !== undefined &&
                        item["isOtherPcHoursInput"]
                          ? `${formatNumberWithUnit(
                              item[i.hourKey],
                              null,
                              0
                            )}hr*`
                          : item[i.hourKey] !== undefined
                          ? `${formatNumberWithUnit(
                              item[i.hourKey],
                              null,
                              0
                            )}hr`
                          : item["isSavingsInput"]
                          ? "----"
                          : "N/A"}
                      </span>
                    </Stack>
                  ) : (
                    <>{getCustomizeValue(item, i.valueKey, currencySymbol)}</>
                  )}
                </Stack>
              ))}
            </Stack>
          );
        })}
        <Stack
          horizontal
          className={`${tableStyles.tableRow} ${tableStyles.withoutBottom} tco-report-table-row`}
          tabIndex={0}
        >
          <Stack.Item
            className={`${tableStyles.totalLabel} tco-report-table-total-label`}
          >
            Total
          </Stack.Item>
          {tableTitleList.map((i) => (
            <Stack horizontal className={tableStyles.tableRow2} key={i.label}>
              <div className="mobile-display-only tco-report-mobile-table-row-label">
                {i.label}
              </div>
              {showHours ? (
                <Stack
                  horizontal
                  className={`${tableStyles.rowValueHourWrapper} tco-report-table-value-hour-wrapper`}
                >
                  <span className={tableStyles.rowSurfaceValue}>
                    {formatCurrency(data[i.valueKey], currencySymbol, 0)}
                  </span>
                  <span className={tableStyles.rowHourValue}>
                    {data[i.hourKey] !== undefined
                      ? `${formatNumberWithUnit(data[i.hourKey], null, 0)}hr`
                      : ""}
                  </span>
                </Stack>
              ) : (
                <>{formatCurrency(data[[i.valueKey]], currencySymbol, 0)}</>
              )}
            </Stack>
          ))}
        </Stack>
        {hasCustomizedValue ? (
          <Stack
            horizontal
            className={`${tableStyles.inputWarning} tco-report-warning-wrapper`}
          >
            <FontIcon
              iconName="Info"
              tabIndex={0}
              className={tableStyles.infoIcon}
            />
            <span className={tableStyles.inputWarningText}>
              * These values are taken directly from your user input in the
              Results Editor.
            </span>
          </Stack>
        ) : null}
      </div>
    );
  };

  const chartDataListSustainability = (data, color) => {
    let sustainabilityUserInput = userInput.sustainability;
    let quantity =
      sessionStorage.getItem("showPerDevice") === "true"
        ? 1
        : convertStringToNumber(userInput.deviceMixPortfolio[0]?.quantity);
    let years = selectedYears ? Number(selectedYears) : 3;

    const dataObject = {
      Energy: {
        surfaceCol1: 0,
        surfaceCol2:
          convertStringToNumber(sustainabilityUserInput.Energy.surfaceValue) *
          quantity *
          years,
        otherPcCol1: 0,
        otherPcCol2:
          convertStringToNumber(sustainabilityUserInput.Energy.otherPcValue) *
          quantity *
          years,
        savingsCol1: 0,
        savingsCol2:
          convertStringToNumber(sustainabilityUserInput.Energy.otherPcValue) *
            quantity *
            years -
          convertStringToNumber(sustainabilityUserInput.Energy.surfaceValue) *
            quantity *
            years,
      },
      CarbonEmissions: {
        surfaceCol1: 0,
        surfaceCol2:
          convertStringToNumber(
            sustainabilityUserInput.CarbonEmissions.surfaceValue
          ) *
          quantity *
          years,
        otherPcCol1: 0,
        otherPcCol2:
          convertStringToNumber(
            sustainabilityUserInput.CarbonEmissions.otherPcValue
          ) *
          quantity *
          years,
        savingsCol1: 0,
        savingsCol2:
          convertStringToNumber(
            sustainabilityUserInput.CarbonEmissions.otherPcValue
          ) *
            quantity *
            years -
          convertStringToNumber(
            sustainabilityUserInput.CarbonEmissions.surfaceValue
          ) *
            quantity *
            years,
      },
      totalValues: {
        surfaceCol1: data.surfaceValue,
        otherPcCol1: data.pcValue,
        savingsCol1: data.savings,
      },
    };

    data.rows?.map((row) => {
      if (row.label === "Total Energy Consumption Savings") {
        dataObject.Energy.surfaceCol1 = row.surfaceValue;
        dataObject.Energy.otherPcCol1 = row.pcValue;
        dataObject.Energy.savingsCol1 = row.savings;
      } else if (row.label === "Total Carbon Emissions Savings") {
        dataObject.CarbonEmissions.surfaceCol1 = row.surfaceValue;
        dataObject.CarbonEmissions.otherPcCol1 = row.pcValue;
        dataObject.CarbonEmissions.savingsCol1 = row.savings;
      }
    });

    const tableTitleList = [
      {
        label: "Surfaces",
        valueKey1: "surfaceCol1",
        valueKey2: "surfaceCol2",
      },
      {
        label: "Other PCs",
        valueKey1: "otherPcCol1",
        valueKey2: "otherPcCol2",
      },
      {
        label: "Savings",
        valueKey1: "savingsCol1",
        valueKey2: "savingsCol2",
      },
    ];

    const getCustomizeValue = (item, key, prefix) => {
      let result;

      if (item.label === "Total Energy Consumption Savings") {
        if (prefix === "Kwh" || prefix === "MTCO2") {
          result = dataObject.Energy[key] + " " + prefix;
        } else {
          result = formatCurrency(dataObject.Energy[key], prefix, 0);
        }
      } else if (item.label === "Total Carbon Emissions Savings") {
        if (prefix === "Kwh" || prefix === "MTCO2") {
          result = dataObject.CarbonEmissions[key] + " " + prefix;
        } else {
          result = formatCurrency(dataObject.CarbonEmissions[key], prefix, 0);
        }
      } else {
        if (prefix === "Kwh" || prefix === "MTCO2") {
          result = dataObject.totalValues[key] + "" + prefix;
        } else {
          result = formatCurrency(dataObject.totalValues[key], prefix, 0);
        }
      }

      return result;
    };

    return (
      <div className={`${tableStyles.tableWrapper} tco-report-table-wrapper`}>
        <Stack
          horizontal
          className={`${tableStyles.tableTitleWrapper} tco-report-table-title mobile-hide`}
          tabIndex={0}
        >
          <div className={tableStyles.tableTitle1}></div>
          {tableTitleList.map((item, index) => (
            <Stack.Item
              key={index}
              className={`${tableStyles.tableTitle} ${tableStyles.withBottom}`}
            >
              {item.label}
            </Stack.Item>
          ))}
        </Stack>
        {data?.rows.map((item, index) => {
          const markerColor = { backgroundColor: color[index] };
          const col2Unit =
            item.label === "Total Energy Consumption Savings" ? "Kwh" : "MTCO2";
          return (
            <Stack
              horizontal
              key={index}
              className={`${tableStyles.tableRow} tco-report-table-row ${
                item.savings < 0 ? tableStyles.greyBackground : ""
              }`}
              tabIndex={0}
            >
              <Stack.Item className={tableStyles.tableRow1}>
                <div className={tableStyles.marker} style={markerColor}></div>
                <div>{item.label}</div>
              </Stack.Item>
              {tableTitleList.map((i) => (
                <Stack
                  horizontal
                  className={tableStyles.tableRow2}
                  key={i.label}
                >
                  <div className="mobile-display-only tco-report-mobile-table-row-label">
                    {i.label}
                  </div>
                  <Stack
                    horizontal
                    className={`${tableStyles.rowValueHourWrapper} tco-report-table-value-hour-wrapper`}
                  >
                    <span className={tableStyles.rowSurfaceValue}>
                      {getCustomizeValue(item, i.valueKey1, currencySymbol)}
                    </span>
                    <span className={tableStyles.rowHourValue}>
                      {getCustomizeValue(item, i.valueKey2, col2Unit)}
                    </span>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          );
        })}
        <Stack
          horizontal
          className={`${tableStyles.tableRow} ${tableStyles.withoutBottom} tco-report-table-row`}
          tabIndex={0}
        >
          <Stack.Item
            className={`${tableStyles.totalLabel} tco-report-table-total-label`}
          >
            Total
          </Stack.Item>
          {tableTitleList.map((i) => (
            <Stack horizontal className={tableStyles.tableRow2} key={i.label}>
              <div className="mobile-display-only tco-report-mobile-table-row-label">
                {i.label}
              </div>
              <Stack
                horizontal
                className={`${tableStyles.rowValueHourWrapper} tco-report-table-value-hour-wrapper`}
              >
                <span className={tableStyles.rowSurfaceValue}>
                  {getCustomizeValue(
                    { label: "total" },
                    i.valueKey1,
                    currencySymbol
                  )}
                </span>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </div>
    );
  };

  const reportStyles = mergeStyleSets({
    contentWrapper: {
      display: "flex",
      flexFlow: "column nowrap",
      width: "85vw",
      height: "85vh",
      color: "#201F1E",
    },
    cancelIcon: {
      color: "#474747",
      alignSelf: "flex-end",
      fontSize: "22.5px",
      cursor: "pointer",
      position: "sticky",
      top: "1.4844rem",
      marginRight: "2.2344rem",
    },
    title: {
      fontSize: "1.25rem",
      lineHeight: "1.75rem",
      fontWeight: 600,
      margin: "2.5rem 0 2.5rem 2.5rem",
      textTransform: "capitalize",
    },
    introWrapper: {
      width: "45%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: "1.5rem",
    },
    introLeftPart: {
      width: "78.5%",
      backgroundColor: "#f3f2f1",
      padding: "2rem",
    },
    introLeftPartTitle: {
      fontSize: "2.25rem",
      fontWeight: 700,
      lineHeight: "3rem",
      color: "#0078D4",
    },
    introLeftPartText: {
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "1.5375rem",
      marginBottom: "1rem",
      color: "#000000",
      textTransform: "capitalize",
    },
    introLeftPartTextAdditional: {
      marginBottom: "2rem",
    },
    introRightPart: {
      padding: "0 3.4375rem 0 2rem",
    },
    introRightPartText: {
      fontSize: "0.65rem",
      fontWeight: 400,
      lineHeight: "1rem",
      color: "#000000",
    },
    introRightPartContent: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: "1.5625rem",
      marginBottom: "1.3125rem",
      marginLeft: "0.75rem",
      color: "#201F1E",
    },
    introParagraph: {
      color: "#191919",
      marginBottom: "2.5rem",
      fontWeight: 600,
      width: "47.69%",
      margin: "0 3rem 0 2.5rem",
      p: {
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: "1.33rem",
      },
      "p:first-child": {
        marginTop: "0",
        marginBottom: "1.375rem",
      },
    },
    sectionWrapperMainreport: {
      padding: "0 3.375rem",
      marginBottom: "2.375rem",
      backgroundColor: "#f3f2f1",
    },
    sectionWrapper: {
      padding: "0 3.375rem",
      marginBottom: "1.375rem",
    },
    sectionWrapperHidden: {
      padding: "0 3.375rem",
      marginBottom: "1.375rem",
      visibility: "hidden",
      height: 0,
    },
    chartWrapperHidden: {
      marginBottom: "1.3125rem",
      visibility: "hidden",
      height: 0,
    },
    sectionTitle: {
      fontSize: "1.625rem",
      fontWeight: 400,
      lineHeight: "2.1875rem",
      textTransform: "capitalize",
      marginTop: 0,
      marginBottom: "3.5rem",
    },
    sectionTitleAdditional: {
      marginBottom: "0rem",
      h2: {
        color: "#191919",
        fontWeight: 400,
        fontSize: "1rem",
      },
    },
    chartWrapper: {
      marginBottom: "1.3125rem",
    },

    chart: {
      width: "50%",
    },
    spinner: {
      height: "100%",
    },
    incidentsItemWrapper: {
      width: "50%",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    incidentsTitle: {
      fontSize: "1.25rem",
      fontWeight: 400,
      lineHeight: "1.6875rem",
    },
    incidentsValueWrapper: {
      gap: "0.5rem",
    },
    incidentsValue: {
      fontSize: "2.625rem",
      fontWeight: 600,
      lineHeight: "3.9375rem",
    },
    incidentsUnit: {
      alignSelf: "flex-end",
      fontSize: "1.5rem",
      fontWeight: 600,
      lineHeight: "3rem",
    },
    incidentsDes: {
      fontSize: "1.25rem",
      fontWeight: 600,
      lineHeight: "1.875rem",
      alignItems: "center",
    },
    pdfLinkWrapper: {
      paddingTop: "6.5625rem",
      "> div:first-child": {
        marginBottom: "6.5625rem",
      },
    },
    pdfLink: {
      fontSize: "1.25rem",
      fontWeight: 600,
      lineHeight: "1.875rem",
      textDecoration: "underline",
      cursor: "pointer",
    },
    subSection: {
      marginBottom: "9.375rem",
    },
    costsAndSavings: {
      "> div:first-child": {
        paddingRight: "0",
        width: "95%",
      },
    },
    costsAndSavingsAdditional: {
      "> div:first-child": {
        marginBottom: "1rem",
      },
    },
  });

  const chartData = useMemo(() => {
    if (isObjectEmpty(completeReport)) {
      return {};
    }
    let chartData = {};
    const convertToChartData = (data) => {
      return data.rows.map((item) => {
        return {
          name: item.label,
          value: [item.surfaceValue || 0, item.pcValue || 0],
          stack:
            item.label === "Increased Residual Value" ? "residual" : "stack",
          barWidth: data.rows.find(
            (i) => i.label === "Increased Residual Value"
          )
            ? "30%"
            : isSmallWidthScreen()
            ? "60"
            : "35%",
        };
      });
    };
    chartData.totalCosts = {
      chartData: convertToChartData(completeReport.totalCosts).reverse(),
      label: ["Surface", "PC"],
      currencySymbol: currencySymbol,
    };
    chartData.totalSavings = {
      chartData: completeReport.totalSavings?.rows
        .map((item) => {
          return {
            name: item.label,
            value: [item.savings],
            stack: "stack",
            barWidth: "20%",
          };
        })
        ?.reverse(),
      label: ["Savings"],
      currencySymbol: currencySymbol,
    };
    chartData.itSimplification = {
      chartData: convertToChartData(completeReport.itSimplification).reverse(),
      label: ["Surface", "PC"],
      currencySymbol: currencySymbol,
    };

    chartData.savingAndBenefit = [
      {
        name: "Total Direct Savings",
        value: completeReport.totalSavings?.savings,
      },
      {
        name: "Total IT Efficiency Benefits",
        value: completeReport.itSimplification?.savings,
      },
      {
        name: "Total Employee Experience Benefits",
        value: completeReport.employeeEmpowerment?.savings,
      },
      {
        name: "Total Sustainability Savings",
        value: completeReport.sustainability?.savings,
      },
    ];
    const employeeChartData = convertToChartData(
      completeReport.employeeEmpowerment
    );
    chartData.employeeEmpowerment = {
      chartData: employeeChartData.reverse(),
      label: ["Surface", "PC"],
      currencySymbol: currencySymbol,
    };

    chartData.incidentsSaving = {
      helpDesk: completeReport.itSimplification.rows.find(
        (i) => i.label === "Reduced Helpdesk Support"
      ),
      security: completeReport.itSimplification.rows.find(
        (i) => i.label === "Reduced Time for Security Incident Response"
      ),
    };

    chartData.sustainability = {
      chartData: completeReport.sustainability?.rows
        .map((item) => {
          return {
            name: item.label,
            value: [item.savings],
            stack: "stack",
            barWidth: "15%",
          };
        })
        ?.reverse(),
      label: ["Savings"],
      currencySymbol: currencySymbol,
    };

    return chartData;
  }, [completeReport]);

  const uncheckWarning = (key) => (
    <>
      {uncheckList[key].length > 0 ? (
        <Stack
          horizontal
          className={`${tableStyles.inputWarning} tco-report-warning-wrapper`}
        >
          <FontIcon
            iconName="Info"
            tabIndex={0}
            className={tableStyles.infoIcon}
          />
          <span className={tableStyles.inputWarningText}>
            * The following categories are not included because they were
            unselected : {uncheckList[key].join(", ")}
          </span>
        </Stack>
      ) : null}
    </>
  );

  const hasValidChartData = (data) => {
    if (!data || !data.rows) return false;
    const isAllValueModified = data.rows.every(
      (i) => i.isSavingsInput === true
    );
    if (data.rows.length > 0 && !isAllValueModified) {
      return true;
    }
    return false;
  };

  const summary = (
    <div className={`${standardStyles.summaryWrapper} summary-wrapper`}>
      <div
        className={`${standardStyles.summaryLinesWrapper} ${
          chatbotExpanded
            ? "summary-lines-wrapper-reduced"
            : "summary-lines-wrapper"
        }`}
      >
        <div className={`${standardStyles.summaryLine}`}></div>
        <div
          className={`${standardStyles.summaryTitle} tco-standard-view-summary-title`}
        >
          Summary
        </div>
        <div className={`${standardStyles.summaryLine}`}></div>
      </div>
      <div
        className={`${standardStyles.summaryContainer} ${
          chatbotExpanded ? "summary-container-reduced" : "summary-container"
        }`}
      >
        <div
          className={`${standardStyles.summaryItem} ${
            chatbotExpanded ? "summary-item-reduced" : "summary-item"
          }`}
        >
          <h2>{formatCurrency(advancedData.totalCosts, currencySymbol, 0)}</h2>
          <br></br>
          <p>Total cost</p>
        </div>
        <div
          className={`${standardStyles.summaryItem} ${
            chatbotExpanded ? "summary-item-reduced" : "summary-item"
          }`}
        >
          <h2>
            {formatCurrency(advancedData.totalSavings, currencySymbol, 0)}
          </h2>
          <br></br>
          <p>Total savings</p>
        </div>
        <div
          className={`${standardStyles.summaryItem} ${
            chatbotExpanded ? "summary-item-reduced" : "summary-item"
          }`}
        >
          <h2 className="capitalize">
            {advancedData.totalCosts
              ? calculateNPVAndBPB(
                  advancedData.totalCosts,
                  advancedData.totalSavings,
                  "PBP",
                  "Advanced",
                  discountRate.pbp
                )
              : "0 Months"}
          </h2>
          <br></br>
          <p>
            <>Payback period</>
            <FontIcon
              id={summaryPaybackId}
              iconName="Info"
              tabIndex={0}
              className={`${standardStyles.infoIconUpdated} info-icon-updated tco-assumptions-right-part-title-icon`}
              onClick={toggleIsPaybackCalloutVisible}
              directionalHint={DirectionalHint.rightCenter}
            />
            {isPaybackCalloutVisible && (
              <Callout
                className={calloutStyles.calloutForSummaryItems}
                target={`#${summaryPaybackId}`}
                onDismiss={toggleIsPaybackCalloutVisible}
                role="alert"
                gapSpace={0}
              >
                <Text>
                  PBP = (Total Savings / ( 1 + Discount Rate*) ^ No.of years* )
                  / Total Cost
                </Text>
                <Text>*Discount Rate = 6.20%</Text>
                <Text>*No.of years = {selectedYears}</Text>
              </Callout>
            )}
          </p>
        </div>
        <div
          className={`${standardStyles.summaryItem} ${
            chatbotExpanded ? "summary-item-reduced" : "summary-item"
          }`}
        >
          <h2>
            {formatCurrency(
              calculateNPVAndBPB(
                advancedData.totalCosts,
                advancedData.totalSavings,
                "NPV",
                "Advanced",
                discountRate.npv
              ),
              currencySymbol,
              0
            )}
          </h2>
          <br></br>
          <p>
            <>Net Present Value</>
            <FontIcon
              id={summaryNPVId}
              iconName="Info"
              tabIndex={0}
              className={`${standardStyles.infoIconUpdated} info-icon-updated tco-assumptions-right-part-title-icon`}
              onClick={toggleIsNPVCalloutVisible}
              directionalHint={DirectionalHint.rightCenter}
            />
            {isNPVCalloutVisible && (
              <Callout
                className={calloutStyles.calloutForSummaryItems}
                target={`#${summaryNPVId}`}
                onDismiss={toggleIsNPVCalloutVisible}
                role="alert"
                gapSpace={0}
              >
                <Text>
                  NPV = (Total Savings / ( 1 + Discount Rate*) ^ No.of years* )
                  - Total Cost
                </Text>
                <Text>*Discount Rate = 6.20%</Text>
                <Text>*No.of years = {selectedYears}</Text>
              </Callout>
            )}
          </p>
        </div>
      </div>
    </div>
  );

  const standardView = (
    <Stack
      className={`${standardStyles.container} tco-standard-view-content-wrapper`}
    >
      <div
        className={`${standardStyles.section} tco-standard-view-first-section`}
      >
        {isSmallWidthScreen() ? (
          <div className={standardStyles.mobileChartWrapper}>
            {simpleReport.details?.map((item, index) => {
              const chartTitleList = [
                {
                  id: "device-definition",
                  title: "Device",
                  def: tooltipData["deviceDefinition"].replace(
                    /{YEAR}/g,
                    numberToWords(selectedYears)
                  ),
                  callOut: toggleDeviceCalloutVisible,
                  callOutVisibility: isDeviceCalloutVisible,
                },
                {
                  id: "it-definition",
                  title: "IT Efficiency",
                  def: tooltipData["itEfficiencyDefinition"].replace(
                    /{YEAR}/g,
                    numberToWords(selectedYears)
                  ),
                  callOut: toggleITCalloutVisible,
                  callOutVisibility: isITCalloutVisible,
                },
                {
                  id: "employee-definition",
                  title: "Employee Experience",
                  def: tooltipData["employeeExperienceDefinition"].replace(
                    /{YEAR}/g,
                    numberToWords(selectedYears)
                  ),
                  callOut: toggleEmployeeCalloutVisible,
                  callOutVisibility: isEmployeeCalloutVisible,
                },
              ];
              return (
                <div
                  key={index}
                  className={`${standardStyles.mobileChartWrapper} tco-mobile-chart-wrapper`}
                >
                  <div
                    className={`${standardStyles.titleWrapper} ${standardStyles.titleWrapperMobile} tco-chart-title-wrapper`}
                  >
                    <span>{chartTitleList[index]["title"]}</span>
                    <FontIcon
                      // aria-describedby="assumptions-tooltip"
                      id={chartTitleList[index]["id"]}
                      iconName="Info"
                      tabIndex={0}
                      className={`${standardStyles.infoIcon} tco-assumptions-right-part-title-icon`}
                      onClick={chartTitleList[index]["callOut"]}
                      directionalHint={DirectionalHint.topCenter}
                    />
                    {chartTitleList[index]["callOutVisibility"] && (
                      <Callout
                        className={calloutStyles.callout}
                        target={`#${chartTitleList[index]["id"]}`}
                        onDismiss={chartTitleList[index]["callOut"]}
                        role="alert"
                      >
                        <DelayedRender>
                          <Text variant="small">
                            {chartTitleList[index]["def"]}
                          </Text>
                        </DelayedRender>
                      </Callout>
                    )}
                  </div>
                  <div
                    className={`${standardStyles.mobileChartValue} mobile-chart-value`}
                  >
                    {`${formatNumberWithUnit(
                      item.savings,
                      currencySymbol
                    )} Savings`}
                  </div>
                  <ReactEcharts
                    options={BAR_COMPARISON_CHART_OPTIONS(
                      getMobileCostBarChartData(item),
                      TCO_SIMPLE_REPORT_CHART_COLOR.cost
                    )}
                    width="100%"
                    height="17rem"
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <>
            <Stack
              horizontal
              className={`${standardStyles.sumValueWrapper} ${standardStyles.chartRowWrapper} tco-standard-view-sum-value-wrapper`}
            >
              <div className={`${standardStyles.titleWrapper}`}>
                <span>Device</span>
                <FontIcon
                  // aria-describedby="assumptions-tooltip"
                  id="device-definition"
                  iconName="Info"
                  tabIndex={0}
                  className={`${standardStyles.infoIcon} tco-assumptions-right-part-title-icon`}
                  onClick={toggleDeviceCalloutVisible}
                  directionalHint={DirectionalHint.rightCenter}
                />
                {isDeviceCalloutVisible && (
                  <Callout
                    className={calloutStyles.callout}
                    target={`#device-definition`}
                    onDismiss={toggleDeviceCalloutVisible}
                    role="alert"
                  >
                    <DelayedRender>
                      <Text variant="small">
                        {tooltipData["deviceDefinition"].replace(
                          /{YEAR}/g,
                          numberToWords(selectedYears)
                        )}
                      </Text>
                    </DelayedRender>
                  </Callout>
                )}
              </div>
              <div className={`${standardStyles.titleWrapper}`}>
                <span>IT Efficiency</span>
                <FontIcon
                  // aria-describedby="assumptions-tooltip"
                  id="it-definition"
                  iconName="Info"
                  tabIndex={0}
                  className={`${standardStyles.infoIcon} tooltip tco-assumptions-right-part-title-icon`}
                  onClick={toggleITCalloutVisible}
                  directionalHint={DirectionalHint.rightCenter}
                />
                {isITCalloutVisible && (
                  <Callout
                    className={calloutStyles.callout}
                    target={`#it-definition`}
                    onDismiss={toggleITCalloutVisible}
                    role="alert"
                  >
                    <DelayedRender>
                      <Text variant="small">
                        {tooltipData["itEfficiencyDefinition"].replace(
                          /{YEAR}/g,
                          numberToWords(selectedYears)
                        )}
                      </Text>
                    </DelayedRender>
                  </Callout>
                )}
              </div>
              <div className={`${standardStyles.titleWrapper}`}>
                <span>Employee Experience</span>
                <FontIcon
                  // aria-describedby="assumptions-tooltip"
                  id="employee-definition"
                  iconName="Info"
                  tabIndex={0}
                  className={`${standardStyles.infoIcon} tco-assumptions-right-part-title-icon`}
                  onClick={toggleEmployeeCalloutVisible}
                  directionalHint={DirectionalHint.rightCenter}
                />
                {isEmployeeCalloutVisible && (
                  <Callout
                    className={calloutStyles.callout}
                    target={`#employee-definition`}
                    onDismiss={toggleEmployeeCalloutVisible}
                    role="alert"
                  >
                    <DelayedRender>
                      <Text variant="small">
                        {tooltipData["employeeExperienceDefinition"].replace(
                          /{YEAR}/g,
                          numberToWords(selectedYears)
                        )}
                      </Text>
                    </DelayedRender>
                  </Callout>
                )}
              </div>
            </Stack>
            <ReactEcharts
              options={BAR_COMPARISON_CHART_OPTIONS(
                costBarChartData,
                TCO_SIMPLE_REPORT_CHART_COLOR.cost,
                chatbotExpanded
              )}
              width="100%"
              height="24rem"
            />
          </>
        )}
        <ChartDisclaimer />
      </div>
      {summary}
    </Stack>
  );

  return (
    <Stack>
      <div ref={detailReportRef}></div>
      <CostSavingsBanner />
      <Stack className={commonStyles.container} tokens={{ childrenGap: 16 }}>
        <StackItem>
          <ReportTitle chartData={chartData} userInput={userInput} />
          <ReportDescription />
        </StackItem>
        <StackItem id="itEfficiency">
          <ReportLayout data={reportState?.ItSimiplification} />
        </StackItem>
        <StackItem id="employeeExperience">
          <ReportLayout data={reportState?.employeeEmpowerment} />
        </StackItem>
        <StackItem id="costs">
          <ReportLayout data={reportState?.totalCosts} valueType="costs" />
        </StackItem>
        <StackItem id="savings">
          <ReportLayout data={reportState?.totalSavings} />
        </StackItem>

        {!isObjectEmpty(completeReport) && !isLoading ? (
          <div>
            <section
              className={`${reportStyles.sectionWrapperMainreport} tco-report-section-wrapper`}
            >
              {standardView}
            </section>

            <Stack horizontal>
              <div
                className={`${reportStyles.introParagraph} tco-report-intro-paragraph`}
              >
                <p>
                  The TCO tool provides you with a report that includes
                  estimates of potential costs, savings, and ROI you may achieve
                  by replacing other PC devices with Surface devices and M365.
                  Default values provided in the TCO tool are based on the study
                  "
                  <a href={BUSINESS_CASE_URL} rel="noreferrer" target="_blank">
                    Evaluating the Business Case of Microsoft Surface
                  </a>
                  " conducted by IDC and commissioned by Microsoft in 2022.
                </p>
                <p>
                  The TCO report provided to you is for informational purposes
                  only. You should not interpret the report you receive to be a
                  commitment on the part of Microsoft; actual costs and savings
                  may vary based on your location, purchase method, deployment,
                  usage, and other factors. Neither Microsoft nor IDC makes any
                  representations or warranties, express or implied, as to the
                  information within this website and report.
                </p>
              </div>
              <div
                className={`${reportStyles.introWrapper} tco-report-intro-wrapper`}
              >
                <div
                  className={`${reportStyles.introLeftPart} tco-report-intro-left`}
                >
                  <Text
                    block
                    className={`${reportStyles.introLeftPartTitle} tco-report-intro-left-title`}
                    tabIndex={0}
                  >
                    {formatCurrency(advancedData.totalSavings, currencySymbol, 0)}
                  </Text>
                  <Text
                    block
                    className={`${reportStyles.introLeftPartText} tco-report-intro-left-text`}
                    tabIndex={0}
                  >
                    Expected ${selectedYears ?? 3}
                    -Year Total Savings and Benefits*
                  </Text>
                  <Text
                    block
                    className={`${reportStyles.introLeftPartTitle} tco-report-intro-left-title`}
                    tabIndex={0}
                  >
                    {completeReport.ROI
                      ? convertNumberToString(completeReport.ROI * 100)
                      : 0}
                    %
                  </Text>
                  <Text
                    block
                    className={`${reportStyles.introLeftPartText} ${reportStyles.introLeftPartTextAdditional} tco-report-intro-left-text`}
                    tabIndex={0}
                  >
                    Total return on investment (ROI)*
                  </Text>
                  <Text
                    block
                    className={reportStyles.introRightPartText}
                    tabIndex={0}
                  >
                    * Total Savings & Benefits = Direct Savings + IT Efficiency
                    Savings + Employee Experience Savings
                  </Text>
                  <Text
                    block
                    className={reportStyles.introRightPartText}
                    tabIndex={0}
                  >
                    * ROI = Total Savings & Benefits / Costs
                  </Text>
                </div>
                {/* <div
                className={`${reportStyles.introRightPart} tco-report-intro-right`}
              >
              </div> */}
              </div>
            </Stack>

            <section
              className={`${reportStyles.sectionWrapper} tco-report-section-wrapper`}
            >
              <div className={reportStyles.subSection}>
                <h2
                  className={`${reportStyles.sectionTitle} ${reportStyles.sectionTitleAdditional} tco-report-section-title`}
                  tabIndex={0}
                >
                  Highlights of Costs and Savings Over {selectedYears ?? 3}{" "}
                  Years &nbsp;
                  <Button
                    className={Buttonstyle.tcoButton}
                    appearance="primary"
                    onClick={() => handleButtonClick("deviceCost")}
                  >
                    Customize
                  </Button>
                </h2>
                <p>
                  ${selectedYears ?? 3}
                  -Year Total Costs
                </p>
                <Stack className={reportStyles.chartWrapper}>
                  <Stack horizontal className="tco-report-chart-wrapper">
                    {completeReport.totalCosts?.rows.length > 0 ? (
                      <div className={`${reportStyles.chart} tco-report-chart`}>
                        <ReactEcharts
                          options={STACKED_BAR_CHART_OPTIONS(
                            chartData.totalCosts,
                            TCO_REPORT_CHART_COLOR.totalCosts
                          )}
                          id="chart1"
                          width="100%"
                          height="31.25rem"
                        />
                      </div>
                    ) : null}

                    <Stack className="costsAndSavingsWrapper">
                      <div className={`${reportStyles.costsAndSavings}`}>
                        {chartDescription({
                          // title: "3-Year Total Costs",
                          value: [
                            {
                              val: formatCurrency(
                                completeReport.totalCosts?.surfaceValue,
                                currencySymbol,
                                0
                              ),
                              text: "Expected Costs",
                            },
                          ],
                          readMore:
                            "These costs take into consideration device costs, accessories costs, M365 Licensing costs, and extended warranty & maintenance costs in terms of dollar values. If you would like to learn more, please visit the Methodology section at the bottom of this report.",
                        })}
                      </div>

                      <div
                        className={`${reportStyles.costsAndSavingsAdditional}`}
                      >
                        {uncheckWarning("totalCosts")}
                        {completeReport.totalCosts?.rows.length > 0
                          ? chartDataList(
                              completeReport.totalCosts,
                              TCO_REPORT_CHART_COLOR.totalCosts
                                .slice(
                                  0,
                                  completeReport.totalCosts?.rows.length
                                )
                                .reverse()
                            )
                          : null}
                      </div>
                    </Stack>
                  </Stack>
                  <ChartDisclaimer />
                </Stack>
              </div>
              <Stack
                horizontal
                className={`${reportStyles.chartWrapper} tco-report-chart-wrapper`}
              >
                <div className={`${reportStyles.chart} tco-report-chart`}>
                  <ReactEcharts
                    options={PIE_CHART_OPTIONS(
                      chartData.savingAndBenefit,
                      TCO_REPORT_CHART_COLOR.savingsAndBenefits,
                      currencySymbol
                    )}
                    id="chart2"
                    width="100%"
                    height="18.75rem"
                  />
                </div>
                {chartDescription(
                  {
                    title: `Total Surface Savings and Benefits over ${
                      selectedYears ?? 3
                    } Years`,
                    value: [
                      {
                        val: formatCurrency(
                          completeReport.savings,
                          currencySymbol,
                          0
                        ),
                      },
                    ],
                  },
                  chartData.savingAndBenefit,
                  TCO_REPORT_CHART_COLOR.savingsAndBenefits,
                  <Button
                    className={Buttonstyle.tcoButton}
                    appearance="primary"
                    onClick={() => handleButtonClick("deviceSavings")}
                  >
                    Customize
                  </Button>
                )}
              </Stack>
            </section>
            <section
              className={`${reportStyles.sectionWrapper} tco-report-section-wrapper`}
            >
              <h2
                className={`${reportStyles.sectionTitle} tco-report-section-title`}
                tabIndex={0}
              >
                Total Surface Savings and Benefits Breakdown Over{" "}
                {selectedYears ?? 3} Years &nbsp;
                <Button
                  className={Buttonstyle.tcoButton}
                  appearance="primary"
                  onClick={() => handleButtonClick("deviceCost")}
                >
                  Customize
                </Button>
              </h2>
              <div className={reportStyles.subSection}>
                <Stack
                  horizontal
                  className={`${reportStyles.chartWrapper} tco-report-chart-wrapper`}
                >
                  {chartDescription({
                    title: `${selectedYears ?? 3} Year Total Direct Savings`,
                    value: [
                      {
                        val: formatCurrency(
                          completeReport.totalSavings?.savings,
                          currencySymbol,
                          0
                        ),
                        text: "Expected Savings",
                      },
                    ],
                    readMore: `Your ${
                      selectedYears ?? 3
                    } year total savings take into consideration the increased residual value, reduced support costs, and accessories savings in terms of dollar values. If you would like to learn more, please visit the Methodology section at the bottom of this report.`,
                  })}
                  {hasValidChartData(completeReport.totalSavings) ? (
                    <div className={`${reportStyles.chart} tco-report-chart`}>
                      <ReactEcharts
                        options={STACKED_BAR_CHART_OPTIONS(
                          chartData.totalSavings,
                          TCO_REPORT_CHART_COLOR.totalSavings
                        )}
                        width="100%"
                        height="31.25rem"
                        id="chart3"
                      />
                    </div>
                  ) : null}
                </Stack>
                {uncheckWarning("totalSavings")}
                {completeReport.totalSavings?.rows.length > 0
                  ? chartDataList(
                      completeReport.totalSavings,
                      TCO_REPORT_CHART_COLOR.totalSavings
                        .slice(0, completeReport.totalSavings?.rows.length)
                        .reverse()
                    )
                  : null}
                <h2
                  className={`${reportStyles.sectionTitle} tco-report-section-title`}
                  tabIndex={0}
                >
                  Methodology
                </h2>
                <p>
                  IDC Research Study conducted from surveys and interviews
                  between December 2021 - February 2022. All respondents were IT
                  decision-makers at large organizations (250-5000+ employees)
                  representing organizations from the United States, Australia,
                  India, Spain, France, United Kingdom, New Zealand, and
                  Germany. Cost & Savings findings based on average cost and
                  time estimates provided directly by respondents; actual costs
                  and savings may vary based on your specific Device Mix and
                  deployment.
                </p>
                <p>
                  *Data point derived from 17 in-depth interviews. All other
                  data points derived from 800 survey results (700 Surface
                  organizations with at least 150 Surface Laptop and 2-in-1
                  Tablets available since 2019, 100 non-Surface organizations).
                  Surface Residual Value adjusted by IDC to reflect average
                  after 36 months.
                </p>
                <p>
                  <b>
                    For the detailed report, click{" "}
                    <a
                      href={BUSINESS_CASE_URL}
                      rel="noreferrer"
                      target="_blank"
                    >
                      here
                    </a>
                    .
                  </b>
                </p>
                <AdditionalLinks industryName={customerInfo.industry} />
                <br></br>
                <BackToTop
                  handleClick={() =>
                    detailReportRef.current.scrollIntoView({
                      behavior: "smooth",
                    })
                  }
                />
              </div>

              <Stack className={reportStyles.chartWrapperHidden}>
                <Stack horizontal className="tco-report-chart-wrapper">
                  {hasValidChartData(completeReport.itSimplification) ? (
                    <div className={`${reportStyles.chart} tco-report-chart`}>
                      <ReactEcharts
                        options={STACKED_BAR_CHART_OPTIONS(
                          chartData.itSimplification,
                          TCO_REPORT_CHART_COLOR.it
                        )}
                        width="100%"
                        height="31.25rem"
                        id="chart4"
                      />
                    </div>
                  ) : null}
                  {chartDescription(
                    {
                      title: `IT Efficiency Savings over ${
                        selectedYears ?? 3
                      } Years`,
                      value: [
                        {
                          val: formatCurrency(
                            completeReport.itSimplification?.savings,
                            currencySymbol,
                            0
                          ),
                          text: "Expected IT-Related Savings",
                        },
                        {
                          val: formatNumberWithUnit(
                            completeReport.itSimplification?.hoursSavings,
                            null,
                            0
                          ),
                          text: "Expected Hour Savings per IT Employee",
                        },
                      ],
                      readMore: `Your ${
                        selectedYears ?? 3
                      } year total IT Efficiency Benefits take into consideration device consolidation, help desk support, time to resolve security incidents, ongoing maintenance, IT deployment costs, IT training costs, and ongoing IT security. This report displays these values in terms of both dollar values and spent man hours. If you would like to learn more, please visit the Methodology section at the bottom of this report.`,
                    },
                    null,
                    TCO_REPORT_CHART_COLOR.it
                  )}
                </Stack>
                <ChartDisclaimer />
              </Stack>
              <section
                className={`${reportStyles.sectionWrapperHidden} tco-report-section-wrapper`}
              >
                {uncheckWarning("itSimplification")}
                {completeReport.itSimplification?.rows.length > 0
                  ? chartDataList(
                      completeReport.itSimplification,
                      TCO_REPORT_CHART_COLOR.it
                        .slice(0, completeReport.itSimplification?.rows.length)
                        .reverse(),
                      true
                    )
                  : null}
              </section>
            </section>
            <section
              className={`${reportStyles.sectionWrapperHidden} tco-report-section-wrapper`}
            >
              <h2
                className={`${reportStyles.sectionTitle} tco-report-section-title`}
                tabIndex={0}
              >
                IT Hours Saved due to Reduced Help Desk and Security Incidents
              </h2>
              <Stack
                horizontal
                className="help-desk-and-security-incidents-wrapper"
              >
                <Stack className={reportStyles.incidentsItemWrapper}>
                  <div
                    className={`${reportStyles.incidentsTitle} help-desk-and-security-incidents-title`}
                  >
                    Help Desk Incidents
                  </div>
                  {chartData.incidentsSaving.helpDesk &&
                  !chartData.incidentsSaving.helpDesk.isSavingsInput ? (
                    <ReactEcharts
                      options={BAR_CHART_OPTIONS(
                        {
                          data: [
                            chartData.incidentsSaving.helpDesk?.surfaceHours,
                            chartData.incidentsSaving.helpDesk?.pcHours,
                          ],
                          label: ["Surface", "Other PC"],
                          unit: "hours",
                        },
                        TCO_REPORT_CHART_COLOR.incidents.helpDesk
                      )}
                      width="30rem"
                      height="26rem"
                      id="chart5"
                    />
                  ) : null}
                  <Stack
                    horizontal
                    className={reportStyles.incidentsValueWrapper}
                  >
                    <span
                      className={`${reportStyles.incidentsValue} help-desk-and-security-incidents-value`}
                    >
                      {formatNumberWithUnit(
                        chartData.incidentsSaving.helpDesk?.hoursSavings,
                        null,
                        0
                      )}
                    </span>
                    <span
                      className={`${reportStyles.incidentsUnit} help-desk-and-security-incidents-unit`}
                    >
                      hours
                    </span>
                  </Stack>
                  <Stack
                    className={`${reportStyles.incidentsDes} help-desk-and-security-incidents-des`}
                  >
                    <span>Fewer</span>
                    <span>Helpdesk Incidents</span>
                  </Stack>
                </Stack>
                <Stack className={reportStyles.incidentsItemWrapper}>
                  <div
                    className={`${reportStyles.incidentsTitle} help-desk-and-security-incidents-title`}
                  >
                    Security Incidents
                  </div>
                  {chartData.incidentsSaving.security &&
                  !chartData.incidentsSaving.security.isSavingsInput ? (
                    <ReactEcharts
                      options={BAR_CHART_OPTIONS(
                        {
                          data: [
                            chartData.incidentsSaving.security?.surfaceHours,
                            chartData.incidentsSaving.security?.pcHours,
                          ],
                          label: ["Surface", "Other PC"],
                          unit: "hours",
                        },
                        TCO_REPORT_CHART_COLOR.incidents.security
                      )}
                      width="30rem"
                      height="26rem"
                      id="chart6"
                    />
                  ) : null}
                  <Stack
                    horizontal
                    className={reportStyles.incidentsValueWrapper}
                  >
                    <span
                      className={`${reportStyles.incidentsValue} help-desk-and-security-incidents-value`}
                    >
                      {formatNumberWithUnit(
                        chartData.incidentsSaving.security?.hoursSavings,
                        null,
                        0
                      )}
                    </span>
                    <span
                      className={`${reportStyles.incidentsUnit} help-desk-and-security-incidents-unit`}
                    >
                      hours
                    </span>
                  </Stack>
                  <Stack
                    className={`${reportStyles.incidentsDes} help-desk-and-security-incidents-des`}
                  >
                    <span>Fewer</span>
                    <span>Security Incidents</span>
                  </Stack>
                </Stack>
              </Stack>
              <ChartDisclaimer />
            </section>
            <section
              className={`${reportStyles.sectionWrapperHidden} tco-report-section-wrapper`}
            >
              {completeReport.employeeEmpowerment ? (
                <>
                  <Stack className={reportStyles.chartWrapperHidden}>
                    <Stack horizontal className="tco-report-chart-wrapper">
                      {chartDescription({
                        title: `Employee Experience Savings over ${
                          selectedYears ?? 3
                        } Years`,
                        value: [
                          {
                            val: formatCurrency(
                              completeReport.employeeEmpowerment?.savings,
                              currencySymbol,
                              0
                            ),
                            text: "Expected Savings",
                          },
                          {
                            val: formatNumberWithUnit(
                              completeReport.employeeEmpowerment?.hoursSavings,
                              null,
                              0
                            ),
                            text: "Expected Hour Savings per Employee",
                          },
                        ],
                        readMore: `Your ${
                          selectedYears ?? 3
                        } year total Employee Experience Benefits take into consideration productivity gains from increased mobility, time spent on help desk resolution, meeting time saved, time spent on security incident resolution, Surface deployment to employees, employee retention, and boot up time. This report displays these values in terms of both dollar values and spent man hours. If you would like to learn more, please visit the Methodology section at the bottom of this report.`,
                      })}
                      {hasValidChartData(completeReport.employeeEmpowerment) ? (
                        <div
                          className={`${reportStyles.chart} tco-report-chart`}
                        >
                          <ReactEcharts
                            options={STACKED_BAR_CHART_OPTIONS(
                              chartData.employeeEmpowerment,
                              TCO_REPORT_CHART_COLOR.employee
                            )}
                            width="100%"
                            height="31.25rem"
                            id="chart7"
                          />
                        </div>
                      ) : null}
                    </Stack>
                    <ChartDisclaimer />
                  </Stack>
                  {uncheckWarning("employeeEmpowerment")}
                  {completeReport.employeeEmpowerment?.rows.length
                    ? chartDataList(
                        completeReport.employeeEmpowerment,
                        TCO_REPORT_CHART_COLOR.employee
                          .slice(
                            0,
                            completeReport.employeeEmpowerment?.rows.length
                          )
                          .reverse(),
                        true
                      )
                    : null}
                </>
              ) : null}
            </section>

            <section
              className={`${reportStyles.sectionWrapperHidden} tco-report-section-wrapper`}
            >
              {completeReport.sustainability ? (
                <>
                  <Stack className={reportStyles.chartWrapperHidden}>
                    <Stack horizontal className="tco-report-chart-wrapper">
                      {chartDescription({
                        title: `Sustainability Savings over ${
                          selectedYears ?? 3
                        } Years`,
                        value: [
                          {
                            val: formatCurrency(
                              completeReport.sustainability?.savings,
                              currencySymbol,
                              0
                            ),
                            text: "Expected Savings",
                          },
                        ],
                      })}
                      {hasValidChartData(completeReport.sustainability) ? (
                        <div
                          className={`${reportStyles.chart} tco-report-chart`}
                        >
                          {/* <ReactEcharts
                          options={STACKED_BAR_CHART_OPTIONS(
                            chartData.sustainability,
                            TCO_REPORT_CHART_COLOR.employee
                          )}
                          width="100%"
                          height="31.25rem"
                          id="chart8"
                        /> */}
                        </div>
                      ) : null}
                    </Stack>
                  </Stack>
                  {/* {uncheckWarning("employeeEmpowerment")} */}
                  {completeReport.sustainability?.rows.length
                    ? chartDataListSustainability(
                        completeReport.sustainability,
                        TCO_REPORT_CHART_COLOR.employee
                          .slice(0, completeReport.sustainability?.rows.length)
                          .reverse(),
                        true
                      )
                    : null}
                </>
              ) : null}
            </section>
          </div>
        ) : (
          <Spinner className={reportStyles.spinner} size={SpinnerSize.large} />
        )}
      </Stack>
    </Stack>
  );
};

export default TcoCompleteReport;
