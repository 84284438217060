import { mergeStyleSets, Stack } from "@fluentui/react";
import {
    Checkbox,
    CheckboxOnChangeData,
    InfoLabel,
    Input,
    Text,
} from "@fluentui/react-components";
import {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useEffect,
    useId,
    useState,
} from "react";
import { CustomAssumptionsItems } from "./AccessoriesAndExtendedWarranty";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import { convertFromOtherToUSD, convertFromUSDToOther, validateDecimal } from "v2/utilities/currencyConverter";
import { formatNumber, isPositiveNumber } from "v2/utilities/helper";
const style = mergeStyleSets({
    extenedWarrantyMainWrapper: {
        paddingLeft: "48px",
    },
    extendedWarrantyFooter: {
        maxWidth: "48%",
    },
    customAssumtionsRowContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
        '@media (max-width: 820px)': { // Change to vertical alignment for smaller resolutions
            flexDirection: 'column',
            flexFlow: 'wrap',
        },
    },
    customAssumtionsRowInput: {

        minWidth: "250px",
        '@media (max-width: 1182px)': {
            minWidth: '100px',
        },
        '@media (max-width: 912px)': {
            maxWidth: '132px !important',
        },
        '@media (max-width: 820px)': {
            maxWidth: '100px !important',
        }
    },
    customAssumtionsRowInputBackground: {
        background: "#F0F0F0 !important",
    },
    customAssumtionsRowCheckbox: {
        alignSelf: "flex-end",
        minWidth: "127px",
        '@media (max-width:768px)': {
            minWidth: '90px !important',
        }

    },
    tooltipStyle: {
        "&& .fui-PopoverSurface": {
            padding: "0.7rem 1.5rem",
            width: 380,
            maxWidth: "90%",
            span: {
                display: "block",
                paddingBottom: "0.7rem",
            },
        },
    },
});
const CustomAssumptionsItem: React.FC<{
    item: CustomAssumptionsItems;
    customAssumptionState: any;
    setCustomAssumptionState: Dispatch<SetStateAction<any>>;
    key: string;
}> = (props) => {
    const baseId = useId();
    const { tcoReportPayload, setTcoReportPayload, currency } = useTcoCalculationContext();
    const {
        customAssumptionState,
        setCustomAssumptionState,
        item,
    } = props;
    const [customInput, setCustomInput] = useState({
        surfaceInput: `${convertFromUSDToOther(
            tcoReportPayload.assumptions[`${item.surfaceInput}`],
            currency
        )}`,
        otherPCInput: `${convertFromUSDToOther(
            tcoReportPayload.assumptions[`${item.otherPCInput}`],
            currency
        )}`,
    });
    useEffect(() => {
        if (customAssumptionState.sameAccessoryCostChecked) {
            setCustomInput({
                ...customInput,
                otherPCInput: `${tcoReportPayload.assumptions[`${item.surfaceInput}`]}`,
            });
            setTcoReportPayload((prev) => ({
                ...prev, assumptions: {
                    ...prev.assumptions,
                    [`${item.otherPCInput}`]: prev.assumptions[`${item.surfaceInput}`],
                }
            }));
        }
    }, [customAssumptionState.sameAccessoryCostChecked]);

    useEffect(() => {
        setCustomInput({
            surfaceInput: `${convertFromUSDToOther(tcoReportPayload.assumptions[`${item.surfaceInput}`], currency)}`,
            otherPCInput: `${convertFromUSDToOther(tcoReportPayload.assumptions[`${item.otherPCInput}`], currency)}`,
        });
    }, [currency]);
    const onCheckboxChange = (
        event: ChangeEvent<HTMLInputElement>,
        data: CheckboxOnChangeData
    ) => {
        setCustomAssumptionState({
            ...customAssumptionState,
            [`${item.status}`]: data.checked === true,
        });
    };
    const checkBoxStatus = () => {
        let status: boolean = false;
        switch (item.name) {
            case "Camera": {
                status = customAssumptionState.customCameraChecked;
                break;
            }
            case "Light": {
                status = customAssumptionState.customLightChecked;
                break;
            }
            case "Headset": {
                status = customAssumptionState.customHeadsetChecked;
                break;
            }
            case "Dock": {
                status = customAssumptionState.customDockChecked;
                break;
            }
            case "Pen": {
                status = customAssumptionState.customPenChecked;
                break;
            }
            case "Keyboard": {
                status = customAssumptionState.customKeyboardChecked;
                break;
            }
            case "Power Supply": {
                status = customAssumptionState.customPowerSupplyChecked;
                break;
            }
        }
        return status;
    };

    const onSurfaceInputChange = (event: React.FocusEvent<HTMLInputElement>) => {
        let otherPCValue = "0";
        if (customAssumptionState.sameAccessoryCostChecked) {
            otherPCValue = customInput.surfaceInput;
            setCustomInput({
                ...customInput,
                otherPCInput: customInput.surfaceInput,
            });
        }
        else {
            otherPCValue = customInput.otherPCInput;
        }

        setTcoReportPayload({
            ...tcoReportPayload,
            assumptions: {
                ...tcoReportPayload.assumptions,
                [`${item.surfaceInput}`]: convertFromOtherCurrencyToUSD(Number(parseFloat(customInput.surfaceInput || "0"))),
                [`${item.otherPCInput}`]: convertFromOtherCurrencyToUSD(Number(otherPCValue || "0")),
            },
        });
    };

    const onOtherPCInputChange = (event: React.FocusEvent<HTMLInputElement>) => {
        setTcoReportPayload({
            ...tcoReportPayload,
            assumptions: {
                ...tcoReportPayload.assumptions,
                [`${item.otherPCInput}`]: convertFromOtherCurrencyToUSD(Number(parseFloat(customInput.otherPCInput || "0"))),
            },
        });
    };
    const convertFromOtherCurrencyToUSD = (currencyValue: number) => {
        if (currency.countryCode === "US") {
            return currencyValue;
        }
        return convertFromOtherToUSD(currencyValue, currency);
    }

    return (
        <Stack
            className={style.customAssumtionsRowContainer}
            horizontal
            tokens={{ childrenGap: 16 }}
        >
            <Checkbox
                className={style.customAssumtionsRowCheckbox}
                id={`${baseId}-custom-${item.name}-checkbox`}
                label={`${item.name}`}
                checked={checkBoxStatus() as boolean}
                onChange={onCheckboxChange}
            />
            <Stack horizontal tokens={{ childrenGap: 16 }}>
                <Stack verticalFill>
                    <InfoLabel size="medium" info="Per device">
                        Surface
                    </InfoLabel>
                    <Input
                        className={`${style.customAssumtionsRowInput} ${!checkBoxStatus() ? style.customAssumtionsRowInputBackground : ''}`}
                        id={`${baseId}-custom-${item.name}-surface`}
                        disabled={!checkBoxStatus() as boolean}
                        contentBefore={
                            <Text size={300} id={`${baseId}-camera-surface-before`}>
                                {currency?.symbol}
                            </Text>
                        }
                        value={formatNumber(`${customInput.surfaceInput}`)}
                        onChange={(event) => {
                            let value = event.target.value;
                            value = value.replaceAll(",", "");
                            if (validateDecimal(value)) {
                                setCustomInput({
                                    ...customInput,
                                    surfaceInput: `${(value)}`,
                                });
                            }

                        }}
                        onBlur={onSurfaceInputChange}
                        placeholder="0"
                    ></Input>
                </Stack>
                <Stack verticalFill>
                    <InfoLabel size="medium" info="Per device">
                        Alternative Device
                    </InfoLabel>
                    <Input
                        className={`${style.customAssumtionsRowInput} ${!checkBoxStatus() ||
                            customAssumptionState.sameAccessoryCostChecked ? style.customAssumtionsRowInputBackground : ''}`}
                        id={`${baseId}-custom-${item.name}-alternate`}
                        disabled={
                            (!checkBoxStatus() as boolean) ||
                            customAssumptionState.sameAccessoryCostChecked
                        }
                        contentBefore={
                            <Text size={300} id={`${baseId}-camera-surface-before`}>
                                {currency?.symbol}
                            </Text>
                        }
                        value={formatNumber(`${customInput.otherPCInput}`)}
                        onChange={(event) => {
                            let value = event.target.value;
                            value = value.replaceAll(",", "");
                            if (validateDecimal(value)) {
                                setCustomInput({
                                    ...customInput,
                                    otherPCInput: `${(value)}`,
                                });
                            }
                        }}
                        onBlur={onOtherPCInputChange}
                        placeholder="0"
                    ></Input>
                </Stack>
            </Stack>
        </Stack>
    );
};
export default CustomAssumptionsItem;
