import { Stack, StackItem, Text } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { InputOnChangeData } from "@fluentui/react-components";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import LabelledInput from "../common/LabelledInput";
import {
  convertFromOtherToUSD,
  convertFromUSDToOther,
  convertStringToNumber,
} from "v2/utilities/currencyConverter";
import tooltipData from "../../store/tooltipData.json";

type EmployeeAssumptionType = {
  [key: string]: string;
};

const EmployeeAssumption: FC = () => {
  const {
    tcoReportPayload,
    setTcoReportPayload,
    discountRate,
    setDisCountRate,
    selectedYears,
    currency,
    selectedCountry,
  } = useTcoCalculationContext();
  const [employeeAssumption, setEmployeeAssumptions] =
    useState<EmployeeAssumptionType>({} as EmployeeAssumptionType);
  const getNPVAndPBPInfo = (
    field: string,
    value: string,
    years: number,
    tooltip: string
  ) => {
    const formulaText = tooltip
      .replace("{FIELD}", field.toUpperCase())
      .replace("{DISCOUNT_RATE}", value)
      .replace("{YEARS}", String(years));
    return (
      <Stack>
        {formulaText.split("\n").map((text, index) => (
          <Text key={index}>{text}</Text>
        ))}
      </Stack>
    );
  };

  const employeeAssumptionData = [
    {
      key: "annualITStaffSalary",
      label: "Average yearly salary of your IT staff?",
      isAmount: true,
    },
    {
      key: "averageCostToHire",
      label: "Average cost to hire a new employee?",
      isAmount: true,
    },
    {
      key: "annualEmployeeSalary",
      label: "Average yearly salary of the end-user?",
      isAmount: true,
    },
    {
      key: "hoursWorkedPerYear",
      label: "Average number of hours worked per year?",
      isAmount: false,
      type: "number" as "number",
    },
    {
      key: "npv",
      label: "Discount rate for the net present value?",
      info: getNPVAndPBPInfo(
        "npv",
        employeeAssumption?.npv,
        selectedYears,
        tooltipData.discountRateNPV
      ),
      isAmount: false,
      type: "number" as "number",
    },
    {
      key: "pbp",
      label: "Discount rate for the payback period?",
      info: getNPVAndPBPInfo(
        "pbp",
        employeeAssumption?.pbp,
        selectedYears,
        tooltipData.discountRatePBP
      ),
      isAmount: false,
      type: "number" as "number",
    },
  ];

  const onChange = (
    ev: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
    key: string
  ) => {
    setEmployeeAssumptions((prev) => ({
      ...prev,
      [key]: data.value,
    }));
  };

  const onBlur = (
    ev: React.FocusEvent<HTMLInputElement, Element>,
    key: string
  ) => {
    const value = ev.target.value;
    const assumption = tcoReportPayload.assumptions[key]?.toString();
    ev.preventDefault();
    if (value !== assumption) {
      if (key === "npv" || key === "pbp") {
        setDisCountRate((prev) => ({
          ...prev,
          [key]: Number(value),
        }));
      } else {
        const valueInNumber = convertStringToNumber(value);
        setTcoReportPayload((prev) => ({
          ...prev,
          assumptions: {
            ...prev.assumptions,
            [key]: convertFromOtherToUSD(valueInNumber, currency),
          },
        }));
      }
    }
  };

  useEffect(() => {
    const data = employeeAssumptionData.reduce((acc, item) => {
      if (Object.keys(tcoReportPayload?.assumptions).includes(item.key)) {
        return {
          ...acc,
          [item.key]:
            selectedCountry.code === "US"
              ? tcoReportPayload.assumptions[item.key]?.toString()
              : item.isAmount
              ? convertFromUSDToOther(
                  tcoReportPayload.assumptions[item.key],
                  currency
                ).toString()
              : tcoReportPayload.assumptions[item.key]?.toString(),
        };
      }
      return acc;
    }, {} as EmployeeAssumptionType);
    setEmployeeAssumptions((preState) => {
      const updatedState = data;

      if (discountRate) {
        updatedState.npv = discountRate.npv.toString();
        updatedState.pbp = discountRate.pbp.toString();
      }

      return updatedState;
    });
  }, [currency]);

  return (
    <Stack
      horizontal
      wrap
      tokens={{ childrenGap: 20 }}
      styles={{ root: { padding: "20px 0px 0px 50px" } }}
    >
      {employeeAssumptionData?.map((item) => (
        <StackItem grow style={{ width: "30%" }} key={item.key}>
          <LabelledInput
            label={item.label}
            infoLabel={item.info ?? ""}
            isAmount={item.isAmount}
            value={`${employeeAssumption[item.key]}`}
            onChange={(ev, data) => {
              onChange(ev, data, item.key);
            }}
            onBlur={(ev) => onBlur(ev, item.key)}
            type={item.type}
          />
        </StackItem>
      ))}
    </Stack>
  );
};

export default EmployeeAssumption;
