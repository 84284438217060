import { FC, useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import Header from "v2/components/common/Header";
import assumptionData from "v2/components/business-assumptions/BusinessAssumptionData.json";
import AssumptionDetailPanel from "v2/components/business-assumptions/AssumptionDetailPanel";
import LabelledDropdown from "v2/components/common/LabelledDropdown";
import { Button, Divider } from "@fluentui/react-components";
import EmployeeAssumption from "v2/components/business-assumptions/EmployeeAssumption";
import ITEfficiency from "v2/components/business-assumptions/ITEfficiency";
import EmployeeExperience from "v2/components/business-assumptions/EmployeeExperience";
import AccessoriesAndExtendedWarranty from "v2/components/business-assumptions/AccessoriesAndExtendedWarranty";
import SurfaceAndOtherPC from "v2/components/business-assumptions/SurfaceAndOtherPC";
import Sustainability from "v2/components/business-assumptions/Sustainability";
import DeviceSavings from "v2/components/business-assumptions/DeviceSavings";
import DeviceCosts from "v2/components/business-assumptions/DeviceCosts";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import { useNavigate } from "react-router-dom";
import { Buttonstyle } from "v2/components/navigation-buttons/NavigationButtons";

import React, { useRef } from "react";
import { useLocation } from "react-router-dom";

import CostSavingsBanner from "v2/components/cost-savings-banner/CostSavingsBanner";

const step = "STEP 3";
const heading = "Review business case assumptions (optional)";
const subHeading =
  "To get a more accurate TCO report, update the assumptions below with your own information or accept default assumptions based on a study conducted by IDC and commissioned by Microsoft in 2022.";

export type BussinessAssumption = {
  id: string;
  title: string;
  subTitle: string;
  iconName: string;
};

interface BusinessAssumptionJson {
  [key: string]: BussinessAssumption;
}

const childrenMapping: { [key: string]: FC } = {
  employeeAssumptions: EmployeeAssumption,
  itEfficiency: ITEfficiency,
  employeeExperience: EmployeeExperience,
  accessoriesAndExternalWarranty: AccessoriesAndExtendedWarranty,
  surfaceAndOtherPCDevices: SurfaceAndOtherPC,
  Sustainability: Sustainability,
  deviceSavings: DeviceSavings,
  deviceCost: DeviceCosts,
  //add other childrens here
};

const BusinessCaseAssumptions: FC = () => {
  const navigate = useNavigate();
  const data = assumptionData as BusinessAssumptionJson;
  const { selectedYears, setSelectedYears, setTcoReportPayload } =
    useTcoCalculationContext();
  useEffect(() => {
    if (selectedYears) {
      setTcoReportPayload((prev) => {
        if (prev.years !== selectedYears) {
          return { ...prev, years: selectedYears };
        }
        return prev;
      });
    }
  }, [selectedYears, setTcoReportPayload]);

  const location = useLocation();
  const focusDivRef = useRef(null);

  const [openItems, setOpenItems] = useState<string[]>([]);

  useEffect(() => {
    const focusDivId = location.state?.focusDivId;
    if (focusDivId) {
      const element = document.getElementById(focusDivId);
      if (element) {
        element.setAttribute("tabindex", "0");
        element.scrollIntoView({ behavior: "smooth" });
        element.focus();
        setOpenItems(focusDivId);
      }
    }
  }, [location.state, navigate]);

  return (
    <Stack>
      <CostSavingsBanner />
      <Stack
        styles={{ root: { padding: "40px", width: "90%" } }}
        tokens={{ childrenGap: 32 }}
      >
        <Header title={heading} subtitle={subHeading} step={step} />
        {Object.keys(data).map((key, index) => {
          const Children = childrenMapping[key];
          return (
            <Stack key={index} tokens={{ childrenGap: 32 }}>
              <AssumptionDetailPanel
                data={data[key]}
                key={key}
                Id={data[key].id}
                openItems={openItems}
                children={Children && <Children />}
              />
              {index === 3 && (
                <Stack tokens={{ childrenGap: 24 }}>
                  <Divider />
                  <LabelledDropdown
                    label={"Number of years for TCO calculation:"}
                    dropdownProp={{ value: `${selectedYears}` }}
                    onSelect={(event, data) =>
                      setSelectedYears(Number(data.optionText))
                    }
                    options={[
                      { id: "1", name: "1" },
                      { id: "2", name: "2" },
                      { id: "3", name: "3" },
                      { id: "4", name: "4" },
                    ]}
                  />
                </Stack>
              )}
            </Stack>
          );
        })}
        <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 24 }}>
          <Button
            onClick={() => navigate("/choose-your-surface-mix")}
            className={Buttonstyle.backButton}
          >
            Back
          </Button>
          <Button
            appearance="primary"
            onClick={() => navigate("/tco-report")}
            className={Buttonstyle.tcoButton}
          >
            View TCO report
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BusinessCaseAssumptions;
