import { Stack, StackItem, Text } from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { InputOnChangeData } from "@fluentui/react-components";
import { useTcoCalculationContext } from "v2/store/TcoCalculationContext";
import LabelledInput from "../common/LabelledInput";
import {
  convertFromOtherToUSD,
  convertFromUSDToOther,
} from "v2/utilities/currencyConverter";
import { TcoReportRow } from "v2/types/apiResponseTypes";
import {
  convertNumberToString,
  convertStringToNumber,
  formatNumber,
} from "utils/helpers";
import { AdvancedInput, AdvancedInputItem } from "v2/types/advancedInput";

const EmployeeExperience: FC = () => {
  const {
    currency,
    tcoCompleteReport,
    setTcoReportPayload,
    selectedCountry,
    tcoReportPayload,
  } = useTcoCalculationContext();

  const [employeeExperience, setEmployeeExperience] = useState<TcoReportRow>(
    {} as TcoReportRow
  );

  const getInfo = (field: string) => {
    return <Stack>{field}</Stack>;
  };
  const EmployeeExperienceData = [
    {
      key: "Reduced Employee Time Lost to Helpdesk",
      label: "Reduced Employee Time Lost to Helpdesk",
      isAmount: false,
      DisplayProperty: "pcHours",
      info: getInfo(
        "Cost calculations are based on the 'annual salary of your end-user' Microsoft Surface devices reduced the time employees spent using HelpDesk, with 7.2 hours spent in comparison to 12.7 for other devices—a difference of 5.5 hours. This resulted in a three-year benefit of $619 and an efficiency improvement of 43 percent. (Refer to IDC study.)"
      ),
    },
    {
      key: "Meeting Time Saved",
      label: "Meeting Time",
      isAmount: false,
      DisplayProperty: "pcHours",
      info: getInfo(
        "Cost calculations are based on the 'annual salary of your end-user' Microsoft Surface devices used 110 hours of meeting time annually in comparison to 118 for other devices—a difference of 8 hours. This resulted in a three-year benefit of $864 and a seven-percent improvement in efficiency. (Refer to IDC study.)"
      ),
    },
    {
      key: "Faster Bootup Time",
      label: "Faster Bootup Time",
      isAmount: false,
      DisplayProperty: "pcHours",
      info: getInfo(
        "Cost calculations are based on the 'annual salary of your end-user' Microsoft Surface devices had faster startup times when compared to other devices, with an annual difference of 0.7 hours. Other devices took 1.9 hours to start, while Microsoft Surface devices only took 1.2 hours. This resulted in a three-year benefit of $81 and a 37-percent improvement in efficiency. (Refer to IDC study.) (Number of reboots per year, per device) * (Time per reboot (minutes), per device) / 60"
      ),
    },
    {
      key: "Reduced Employee Time Lost to Security Incidents",
      label: "Reduced Employee Time Lost to Security Incidents",
      isAmount: false,
      DisplayProperty: "pcHours",
      info: getInfo(
        "Cost calculations are based on the 'annual salary of your end-user' While employees using other devices lost 2.8 hours annually to security incidents, Microsoft Surface devices reduced this time to 2 hours. This resulted in saving 0.8 hours and a gaining a three-year benefit of $89, with a 29-percent improvement in efficiency. (Refer to IDC study.)"
      ),
    },
    {
      key: "Faster Surface Deployment to Employees",
      label: "Faster Surface Deployment to Employees",
      isAmount: false,
      DisplayProperty: "pcHours",
      info: getInfo(
        "Cost calculations are based on the 'annual salary of your end-user' Results demonstrated that it was faster to deploy Microsoft Surface devices to employees in comparison to deploying other devices, with a one-time difference of 1.6 hours. Other devices took 6.1 hours to deploy, while Microsoft Surface devices took 4.5 hours. This resulted in a three-year benefit of $60 and a 26-percent improvement in efficiency. (Refer to IDC study.)"
      ),
    },
  ];

  const onChange = (
    ev: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
    tcoreport: any,
    key: string,
    displayProperty: string
  ) => {
    const tcoreportCopy = { ...tcoreport };
    tcoreportCopy[key][displayProperty] = formatNumber(data.value===""?"0":data.value);
    setEmployeeExperience(tcoreportCopy);
  };

  const onBlur = (
    ev: React.FocusEvent<HTMLInputElement, Element>,
    key: string,
    displayProperty: string,
    tcoreport: any
  ) => {
    const tcoreportCopy = { ...tcoreport };

    let data =
      displayProperty === "pcValue"
        ? selectedCountry.code === "US"
          ? ev.target.value.toString()
          : convertFromOtherToUSD(
              convertStringToNumber(ev.target.value),
              currency
            ).toString()
        : ev.target.value;

    tcoreportCopy[key][displayProperty] = formatNumber(data);

    const advancedinput: AdvancedInput = {};
    const advancedpayload =
      tcoReportPayload.advancedInput === undefined
        ? advancedinput
        : tcoReportPayload.advancedInput;
    tcoReportPayload.advancedInput = advancedpayload;

    if (key == "Reduced Employee Time Lost to Helpdesk") {
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked: true,
        value: tcoreport[key]["savings"],
        surfaceValue: tcoreport[key]["surfaceValue"],
        pcValue: tcoreport[key]["pcValue"],
        pcHours: formatNumber(data),
        isHoursChanged: true,
      };
      advancedpayload.employeeTimeLosttoHelpdesk = advancedAssumptionsInputItem;
    }

    if (key == "Meeting Time Saved") {
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked: true,
        value: tcoreport[key]["savings"],
        surfaceValue: tcoreport[key]["surfaceValue"],
        pcValue: tcoreport[key]["pcValue"],
        pcHours: formatNumber(data),
        isHoursChanged: true,
      };
      advancedpayload.meetingTime = advancedAssumptionsInputItem;
    }

    if (key == "Reduced Employee Time Lost to Security Incidents") {
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked: true,
        value: tcoreport[key]["savings"],
        surfaceValue: tcoreport[key]["surfaceValue"],
        pcValue: tcoreport[key]["pcValue"],
        pcHours: formatNumber(data),
        isHoursChanged: true,
      };
      advancedpayload.employeeTimeLostToSecurityIncidents =
        advancedAssumptionsInputItem;
    }

    if (key == "Faster Surface Deployment to Employees") {
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked: true,
        value: tcoreport[key]["savings"],
        surfaceValue: tcoreport[key]["surfaceValue"],
        pcValue: tcoreport[key]["pcValue"],
        pcHours: formatNumber(data),
        isHoursChanged: true,
      };
      advancedpayload.surfaceDeployment = advancedAssumptionsInputItem;
    }

    if (key == "Faster Bootup Time") {
      const advancedAssumptionsInputItem: AdvancedInputItem = {
        checked: true,
        value: tcoreport[key]["savings"],
        surfaceValue: tcoreport[key]["surfaceValue"],
        pcValue: tcoreport[key]["pcValue"],
        pcHours: formatNumber(data),
        isHoursChanged: true,
      };
      advancedpayload.bootupTime = advancedAssumptionsInputItem;
    }

    let tcoReportPayloadCopy = {
      ...tcoReportPayload,
      advancedInput: advancedpayload,
    };

    setTcoReportPayload(tcoReportPayloadCopy);
  };

  useEffect(() => {
    const data = EmployeeExperienceData.reduce((acc, item) => {
      let rowItem = tcoCompleteReport.employeeEmpowerment?.rows?.filter(
        (row) => row.label === item.key
      )[0] as any;
      if (!rowItem) {
        return acc;
      }
      
      rowItem.pcHours= convertNumberToString(Number(convertStringToNumber(rowItem.pcHours))).toString();
      rowItem.pcValue =
        selectedCountry.code != "US" && item.DisplayProperty != "pcHours"
          ? convertFromUSDToOther(rowItem.pcValue, currency)
          : rowItem.pcValue;

      return {
        ...acc,
        [item.key]: rowItem,
      };
    }, {} as any);

    setEmployeeExperience((preState) => {
      const updatedState = data;
      return updatedState;
    });
  }, [currency, tcoCompleteReport]);

  return (
    <Stack
      horizontal
      wrap
      tokens={{ childrenGap: 20 }}
      styles={{ root: { padding: "20px 0px 0px 50px" } }}
    >
      {EmployeeExperienceData?.map((item) => (
        <StackItem grow style={{ width: "45%" }} key={item.key}>
          <LabelledInput
            label={item.label}
            sublabel={item.isAmount ? "Other PC Value" : "Other PC Hours"}
            isHeadingBold={true}
            isAmount={item.isAmount}
            infoLabel={item.info ?? ""}
            value={`${
              ((employeeExperience as any)[item.key] as any)?.[item.DisplayProperty] 
              }`.toString()}
            onChange={(ev, data) => {
              onChange(
                ev,
                data,
                employeeExperience as any,
                item.key,
                item.DisplayProperty
              );
            }}
            onBlur={(ev) =>
              onBlur(
                ev,
                item.key,
                item.DisplayProperty,
                employeeExperience as any
              )
            }
          />
        </StackItem>
      ))}
    </Stack>
  );
};

export default EmployeeExperience;
